import {
  AccessoriesBlue,
  AccessoriesWhite,
  Admin,
  Card,
  Cashier,
  Dash,
  Drink,
  Event,
  Location,
  Orders,
  Reports,
  Settings,
  ShiftIcon,
  SmartGateBlue,
  SmartGateWhite,
  TableBlue,
  TableWhite,
} from "../../assets/images/sidebar";

export default [
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <Dash color="#808191" />,
    iconActive: <Dash color="#4263eb" />,
    action: "manage",
    resource: "superadmin",
    navLink: "/dashboard",
  },
  {
    id: "location",
    title: "Locations",
    action: "manage",
    resource: "superadmin",
    icon: <Location color="#808191" />,
    iconActive: <Location color="#4263eb" />,
    navLink: "/locations",
  },
  {
    id: "cashier",
    title: "Cashiers",
    icon: <Cashier color="#808191" />,
    iconActive: <Cashier color="#4263eb" />,
    action: "manage",
    resource: "superadmin",
    navLink: "/cashiers",
  },
  {
    id: "shifts",
    title: "Cashiers Shift",
    icon: <ShiftIcon color="#808191" />,
    iconActive: <ShiftIcon color="#4263eb" />,
    action: "manage",
    resource: "superadmin",
    navLink: "/shifts",
  },
  {
    id: "drink",
    title: "Drink/Food Products",
    icon: <Drink color="#808191" />,
    iconActive: <Drink color="#4263eb" />,
    children: [
      {
        id: "category",
        title: "Categories",
        action: "manage",
        resource: "superadmin",
        navLink: "/categories",
      },
      {
        id: "group",
        title: "Groups",
        action: "manage",
        resource: "superadmin",
        navLink: "/groups",
      },
      {
        id: "kitchen",
        title: "Kitchens",
        action: "manage",
        resource: "superadmin",
        navLink: "/kitchens",
      },
      {
        id: "products",
        title: "Products",
        action: "manage",
        resource: "superadmin",
        navLink: "/products",
      },
    ],
  },
  {
    id: "event",
    title: "Events",
    action: "manage",
    resource: "superadmin",
    icon: <Event color="#808191" />,
    iconActive: <Event color="#4263eb" />,
    navLink: "/events",
  },
  {
    id: "smartgate",
    title: "Smart Gates",
    action: "manage",
    resource: "superadmin",
    icon: <SmartGateWhite />,
    iconActive: <SmartGateBlue />,
    navLink: "/smartgate",
  },
  {
    id: "table",
    title: "Tables",
    action: "manage",
    resource: "superadmin",
    icon: <TableWhite />,
    iconActive: <TableBlue />,
    navLink: "/tables",
  },
  {
    id: "accessories",
    title: "Accessories",
    action: "manage",
    resource: "superadmin",
    icon: <AccessoriesWhite />,
    iconActive: <AccessoriesBlue />,
    navLink: "/accessories",
  },
  {
    id: "rfid",
    title: "RFID  Cards",
    icon: <Card color="#808191" />,
    iconActive: <Card color="#4263eb" />,
    children: [
      {
        id: "cards",
        title: "Cards",
        action: "manage",
        resource: "superadmin",
        navLink: "/cards",
      },
      {
        id: "refund",
        title: "Refunds",
        action: "manage",
        resource: "superadmin",
        navLink: "/refund",
      },
      {
        id: "createrfids",
        title: "Create Bulk Cards",
        action: "manage",
        resource: "superadmin",
        navLink: "/createBulkCards",
      },
    ],
  },
  {
    id: "tickets",
    title: "Tickets",
    action: "manage",
    resource: "superadmin",
    icon: <Card color="#808191" />,
    iconActive: <Card color="#4263eb" />,
    navLink: "/ticketList",
  },
  {
    id: "orders",
    title: "Orders",
    icon: <Orders color="#808191" />,
    iconActive: <Orders color="#4263eb" />,
    children: [
      {
        id: "regular",
        title: "Regular Orders",
        action: "manage",
        resource: "superadmin",
        navLink: "/regularOrders",
      },
      {
        id: "regularorderexport",
        title: "Export Regular Orders",
        action: "manage",
        resource: "superadmin",
        navLink: "/regularOrderExport",
      },
      {
        id: "rfidorder",
        title: "RFID Orders",
        action: "manage",
        resource: "superadmin",
        navLink: "/rfidOrders",
      },
      {
        id: "rfidorderexport",
        title: "Export RFID Orders",
        action: "manage",
        resource: "superadmin",
        navLink: "/rfidOrdersexport",
      },
      {
        id: "freeze",
        title: "Freezed Orders",
        action: "manage",
        resource: "superadmin",
        navLink: "/freezedOrders",
      },
    ],
  },
  {
    id: "reports",
    title: "Reports",
    icon: <Reports color="#808191" />,
    iconActive: <Reports color="#4263eb" />,
    children: [
      {
        id: "hourlyproductsale",
        title: "Hourly Product Sales",
        action: "manage",
        resource: "superadmin",
        navLink: "/hourlproductsales",
      },
      {
        id: "productsale",
        title: "Product Sales",
        action: "manage",
        resource: "superadmin",
        navLink: "/productSales",
      },
      {
        id: "sales",
        title: " Location Sales",
        action: "manage",
        resource: "superadmin",
        navLink: "/salesReport",
      },
      {
        id: "sale",
        title: " Cashier Sales",
        action: "manage",
        resource: "superadmin",
        navLink: "/cashierSales",
      },
      // {
      //   id: "productsReport",
      //   title: "Management Sales",
      //   action: "manage",
      //   resource: "superadmin",
      //   navLink: "/managementSales",
      // },
    ],
  },
  /*{
    id: 'tool',
    title: "Correction Tool",
    icon: <Setting />,
    children: [
      {
        id: 'producttool',
        title: "Product Tool",
        action: 'manage',
        resource: 'superadmin',
        navLink: "/productTool"

      },
      {
        id: 'rfidtool',
        title: "RFID Tool",
        action: 'manage',
        resource: 'superadmin',
        navLink: "/RfidTool"

      }
    ]
  }, */
  {
    id: "admin",
    title: "Admins",
    action: "manage",
    resource: "superadmin",
    icon: <Admin color="#808191" />,
    iconActive: <Admin color="#4263eb" />,
    navLink: "/adminList",
  },
  {
    id: "settings",
    title: "Settings",
    action: "manage",
    resource: "superadmin",
    icon: <Settings color="#808191" />,
    iconActive: <Settings color="#4263eb" />,
    navLink: "/settings",
  },
];
