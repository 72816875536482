export const Plus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-plus"
    >
      <line x1="12" y1="5" x2="12" y2="19"></line>
      <line x1="5" y1="12" x2="19" y2="12"></line>
    </svg>
  );
};

export const Edit = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
    >
      <circle opacity="0.1" cx="20" cy="20" r="20" fill="#74BE7A" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.9594 26.2439L24.7933 17.4065C25.1647 16.9299 25.2967 16.3789 25.1729 15.8179C25.0656 15.3079 24.752 14.8229 24.2816 14.4551L23.1343 13.5437C22.1357 12.7494 20.8976 12.833 20.1879 13.7444L19.4203 14.7402C19.3212 14.8647 19.346 15.0487 19.4698 15.149C19.4698 15.149 21.4094 16.7041 21.4506 16.7376C21.5827 16.863 21.6817 17.0302 21.7065 17.2309C21.7478 17.6238 21.4754 17.9917 21.071 18.0419C20.8811 18.067 20.6996 18.0084 20.5675 17.8997L18.5289 16.2777C18.4299 16.2033 18.2813 16.2192 18.1988 16.3195L13.354 22.5902C13.0404 22.9832 12.9331 23.4932 13.0404 23.9865L13.6594 26.6703C13.6924 26.8125 13.8162 26.9128 13.9648 26.9128L16.6884 26.8794C17.1836 26.871 17.6458 26.6452 17.9594 26.2439ZM21.7731 25.4082H26.2143C26.6476 25.4082 27 25.7652 27 26.2041C27 26.6439 26.6476 27.0001 26.2143 27.0001H21.7731C21.3398 27.0001 20.9874 26.6439 20.9874 26.2041C20.9874 25.7652 21.3398 25.4082 21.7731 25.4082Z"
        fill="#74BE7A"
      />
    </svg>
  );
};

export const Delete = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity="0.1" cx="20" cy="20" r="20" fill="#FF6272" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.101 15.2701C26.3733 15.2701 26.6 15.4962 26.6 15.7839V16.0499C26.6 16.3306 26.3733 16.5637 26.101 16.5637H14.4997C14.2267 16.5637 14 16.3306 14 16.0499V15.7839C14 15.4962 14.2267 15.2701 14.4997 15.2701H16.5407C16.9553 15.2701 17.3161 14.9754 17.4094 14.5596L17.5163 14.0822C17.6824 13.4319 18.229 13 18.8547 13H21.7453C22.3641 13 22.9169 13.4319 23.0769 14.0479L23.1913 14.5589C23.2839 14.9754 23.6447 15.2701 24.06 15.2701H26.101ZM25.0641 24.9937C25.2772 23.0079 25.6502 18.2899 25.6502 18.2423C25.6639 18.0981 25.6169 17.9616 25.5236 17.8517C25.4235 17.7488 25.2969 17.6879 25.1574 17.6879H15.448C15.3077 17.6879 15.1743 17.7488 15.0817 17.8517C14.9878 17.9616 14.9415 18.0981 14.9483 18.2423C14.9495 18.2511 14.9629 18.4172 14.9853 18.6951C15.0847 19.9293 15.3616 23.367 15.5406 24.9937C15.6672 26.1921 16.4535 26.9453 17.5925 26.9726C18.4713 26.9929 19.3768 26.9999 20.3027 26.9999C21.1748 26.9999 22.0605 26.9929 22.9666 26.9726C24.145 26.9523 24.9307 26.2124 25.0641 24.9937Z"
        fill="#FF6272"
      />
    </svg>
  );
};

export const Refresh = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      class="feather feather-rotate-cw"
    >
      <polyline points="23 4 23 10 17 10"></polyline>
      <path d="M20.49 15a9 9 0 1 1-2.12-9.36L23 10"></path>
    </svg>
  );
};
