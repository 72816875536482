import * as actionTypes from "./types";

const initialState = {
  loading: false,
  success: false,
  error: null,
  listfreezeOrder: null,
  crudSuccess: false,
  crudError: null,
};

const listFreezeOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_FREEZEORDER_INITIATED: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.LIST_FREEZEORDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        listfreezeOrder: action.payload,
        success: true,
        error: null,
      };
    }
    case actionTypes.LIST_FREEZEORDER_FAILED: {
      return {
        ...state,
        loading: false,
        listfreezeOrder: null,
        success: false,
        error: action.payload,
      };
    }

    // ** reset errors
    case actionTypes.RESET_ERRORS: {
      return {
        ...state,
        crudError: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default listFreezeOrderReducer;
