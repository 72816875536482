import * as actionTypes from "./types";

const initialState = {
  loading: false,
  success: false,
  error: null,
  listkitchen: null,
  crudSuccess: false,
  crudError: null,
};

const listKitchenReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_KITCHEN_INITIATED: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.LIST_KITCHEN_SUCCESS: {
      return {
        ...state,
        loading: false,
        listkitchen: action.payload,
        success: true,
        error: null,
      };
    }
    case actionTypes.LIST_KITCHEN_FAILED: {
      return {
        ...state,
        loading: false,
        listkitchen: null,
        success: false,
        error: action.payload,
      };
    }

    // ** Create KITCHEN  */
    case actionTypes.CREATE_KITCHEN_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.CREATE_KITCHEN_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.CREATE_KITCHEN_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** Update Kitchen
    case actionTypes.UPDATE_KITCHEN_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.UPDATE_KITCHEN_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.UPDATE_KITCHEN_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** Delete Kitchen
    case actionTypes.DELETE_KITCHEN_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.DELETE_KITCHEN_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.DELETE_KITCHEN_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** reset errors
    case actionTypes.RESET_ERRORS: {
      return {
        ...state,
        crudError: action.payload,
      };
    }
    
    default: {
      return {
        ...state,
      };
    }
  }
};

export default listKitchenReducer;
