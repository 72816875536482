// ** list location Sales
export const LIST_SALES_INITIATED = "LIST_SALES_INITIATED";
export const LIST_SALES_SUCCESS = "LIST_SALES_SUCCESS";
export const LIST_SALES_FAILED = "LIST_SALES_FAILED";

// ** list location Sales
export const LIST_CASHIERSALES_INITIATED = "LIST_CASHIERSALES_INITIATED";
export const LIST_CASHIERSALES_SUCCESS = "LIST_CASHIERSALES_SUCCESS";
export const LIST_CASHIERSALES_FAILED = "LIST_CASHIERSALES_FAILED";

// ** list Sales for Dashboard
export const LIST_SALESDASH_INITIATED = "LIST_SALESDASH_INITIATED";
export const LIST_SALESDASH_SUCCESS = "LIST_SALESDASH_SUCCESS";
export const LIST_SALESDASH_FAILED = "LIST_SALESDASH_FAILED";

// ** user Sales Stats
export const EXPORT_USERSALES_INITIATED = "EXPORT_USERSALES_INITIATED";
export const EXPORT_USERSALES_SUCCESS = "EXPORT_USERSALES_SUCCESS";
export const EXPORT_USERSALES_FAILED = "EXPORT_USERSALES_FAILED";

// ** product Sales Stats
export const LIST_PRODUCT_INITIATED = "LIST_PRODUCT_INITIATED";
export const LIST_PRODUCT_SUCCESS = "LIST_PRODUCT_SUCCESS";
export const LIST_PRODUCT_FAILED = "LIST_PRODUCT_FAILED";

// ** Houlry product Sales Stats
export const LIST_HOURLYPRO_INITIATED = "LIST_HOURLYPRO_INITIATED";
export const LIST_HOURLYPRO_SUCCESS = "LIST_HOURLYPRO_SUCCESS";
export const LIST_HOURLYPRO_FAILED = "LIST_HOURLYPRO_FAILED";

// ** reset error
export const RESET_ERRORS = "RESET_ERRORS";
