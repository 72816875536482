import { getUserData } from "@utils";
import { Navigate } from "react-router-dom";
import { Suspense, useContext } from "react";
import { AbilityContext } from "@src/utility/context/Can";

const PrivateRoute = ({ children, route }) => {
  const ability = useContext(AbilityContext);

  if (route) {
    let action = null;
    let resource = null;
    if (route.meta) {
      action = route.meta.action;
      resource = route.meta.resource;
    }
    const user = getUserData();
    if (!user) return <Navigate to="/login" />;
    if (action) {
      if (user && !ability.can(action || "read", resource)) {
        return <Navigate to="/auth/not-auth" replace />;
      }
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>;
};

export default PrivateRoute;
