import "flatpickr/dist/flatpickr.css";
import Flatpickr from "react-flatpickr";
import "react-widgets/scss/styles.scss";
import { Button } from "react-bootstrap";
import ExportCsv from "../common/ExportCsv";
import { Refresh } from "../../assets/images/table";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleCashierListFetch } from "../../redux/user/userActions";
import { handleListLocationFetch } from "../../redux/location/locationActions";
import { Input, Row, Col, CardBody, CardTitle, Card, CardHeader, Spinner } from "reactstrap";

const RfidOrderExport = () => {
  const [orderedAtFrom, setOrderedAtFrom] = useState();
  const [firstLoad, setFirstLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [createdBy, setCreatedBy] = useState("");
  const [apiCallCount, setApiCallCount] = useState(0);
  const [locationFilter, setLocationFilter] = useState("");
  const [cashierFilter, setCashierFilter] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const dispatch = useDispatch();
  const { listLocations } = useSelector((state) => state.location);
  const { listCashiers } = useSelector((state) => state.cashier);
  const locationsList = listLocations?.results;
  const cashierList = listCashiers?.results;

  const handleExport = () => {
    // Call api endPoint To export table Data
    let endPoint = "transaction/export/all?";
    if (paymentMethod) endPoint += `paymentMethod=${paymentMethod}&`;
    if (locationFilter) endPoint += `location=${locationFilter}&`;
    if (cashierFilter) endPoint += `user=${cashierFilter}&`;
    if (createdBy) endPoint += `createdBy=${createdBy}&`;
    const formattedDateFrom =
      orderedAtFrom && new Date(orderedAtFrom).toISOString();
    if (orderedAtFrom) endPoint += `orderedAt=${formattedDateFrom}&`;
    // call export data function
    setApiCallCount(apiCallCount + 1);
    ExportCsv(endPoint, "RegularRFIDOrdersExport", setLoading);
  };

  const clearDates = () => {
    setOrderedAtFrom(undefined);
  };

  useEffect(() => {
    setApiCallCount(0);
    if (firstLoad) {
      dispatch(handleListLocationFetch({ page: 1, limit: 100 }));
      dispatch(handleCashierListFetch({ page: 1, limit: 100 }));
      setFirstLoad(false);
    }
  }, [orderedAtFrom, locationFilter, cashierFilter]);

  return (
    <Fragment>
      <Card>
        <CardHeader className="border-bottom">
          <CardTitle tag="h4">Export RFID Orders</CardTitle>
        </CardHeader>
        <CardBody>
          <Row className="mt-1 mb-50" style={{ position: "relative" }}>
            <Col lg="3" md="6" className="mb-1">
              <Input
                type="select"
                id="type"
                value={paymentMethod}
                name="type"
                onChange={(e) => {
                  setPaymentMethod(e.target.value);
                }}
              >
                <option value="">Select Payment Method</option>
                <option value="Cash">Cash</option>
                <option value="Pin">Pin</option>
                <option value="Coupons">Coupons</option>
                <option value="RFID Card">RFID Card</option>
                <option value="Representation Gift">Representation Gift</option>
                <option value="Employee Gift">Employee Gift</option>
              </Input>
            </Col>
            <Col lg="3" md="6" className="mb-1">
              <Input
                id="locationFilter"
                name="locationFilter"
                type="select"
                value={locationFilter}
                onChange={(e) => {
                  setLocationFilter(e.target.value);
                }}
              >
                <option value="">Select Location</option>
                {locationsList?.map((loc, i) => (
                  <option key={i} value={loc._id}>
                    {loc.name}
                  </option>
                ))}
              </Input>
            </Col>
            <Col lg="3" md="6" className="mb-1 ">
              <Input
                id="cashierList"
                name="cashierList"
                type="select"
                value={cashierFilter}
                onChange={(e) => setCashierFilter(e.target.value)}
              >
                <option value="">Select Cashier</option>
                {cashierList?.map((loc, i) => (
                  <option key={i} value={loc._id}>
                    {loc.name}
                  </option>
                ))}
              </Input>
            </Col>
            <Col lg="3" md="6" className="mb-1">
              <Input
                type="select"
                id="status"
                value={createdBy}
                name="status"
                onChange={(e) => {
                  setCreatedBy(e.target.value);
                }}
              >
                <option value="">Select Created By</option>
                <option value="customer">Customer</option>
                <option value="user">User</option>
              </Input>
            </Col>
            <Col lg="3" md="6" className="mt-1">
              <Flatpickr
                value={orderedAtFrom}
                id="date-time-picker"
                placeholder="Ordered Date"
                className="form-control"
                onChange={(date) => {
                  setOrderedAtFrom(date);
                }}
                style={{ backgroundColor: "white", color: "#6e6b7b" }}
              />
              <span className="requiredDate">Required Field</span>
            </Col>
            <Col lg="4" md="3" className="mb-1 mt-1 d-flex">
              <div
                className="d-flex align-items-center justify-content-xl-start mr-sm-3 mb-xs-3"
                style={{ marginTop: "-5px" }}
              >
                <span
                  className="d-flex justify-content-center align-items-center"
                  onClick={clearDates}
                  style={{ cursor: "pointer" }}
                >
                  <span style={{ marginRight: "5px" }}>Reset</span> <Refresh />
                </span>
                <Button
                  disabled={
                    (orderedAtFrom === undefined || apiCallCount === 1) && true
                  }
                  variant="primary"
                  className="p-1 date-btn"
                  onClick={handleExport}
                  size="sm"
                  style={{
                    marginLeft: "13px",
                    cursor: "pointer",
                    width: "100px",
                  }}
                >
                  {loading ? <Spinner size="sm" /> : "Export"}
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default RfidOrderExport;
