import { GET_PROFILE_INITIATED, GET_PROFILE_SUCCESS, GET_PROFILE_FAILED, UPDATE_PROFILE_FAILED, UPDATE_PROFILE_INITIATED, UPDATE_PROFILE_SUCCESS, RESET_STATE } from "./types";

const profileReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PROFILE_INITIATED: {
      return { ...state, inProcess: true };
    }
    case GET_PROFILE_SUCCESS: {
      return {
        ...state,
        inProcess: false,
        profile: action.payload,
        error: null,
      };
    }
    case GET_PROFILE_FAILED: {
      return {
        ...state,
        inProcess: false,
        error: action.payload,
      };
    }
    
    case UPDATE_PROFILE_INITIATED: {
      return {
        ...state,
        updateProfileInProcess: true,
      };
    }
    case UPDATE_PROFILE_SUCCESS: {
      return {
        ...state,
        updateProfileInProcess: false,
        profile: action.payload,
        success: true,
        error: null,
      };
    }
    case UPDATE_PROFILE_FAILED: {
      return {
        ...state,
        updateProfileInProcess: false,
        success: false,
        error: action.payload,
      };
    }

    case RESET_STATE: {
      return {
        ...state,
        success: false,
        error: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default profileReducer;
