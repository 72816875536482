import * as actionTypes from "./types";

const initialState = {
    loading: false,
    success: false,
    error: null,
    cashierDetail: null,
    cashierHistory : null,
    crudSuccess: false,
    crudError: null,
};

const CashierShift = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.FETCH_CASHIER_HISTORY_INIT: {
            return {
                ...state,
                loading: true,
                cashierHistory: null,
            };
        }
        case actionTypes.FETCH_CASHIER_HISTORY_SUCCESS: {
            return {
                ...state,
                loading: false,
                cashierHistory: action.payload,
                crudError: null,
            };
        }
        case actionTypes.FETCH_CASHIER_HISTORY_FAILED: {
            return {
                ...state,
                loading: false,
                cashierHistory:null,
                crudError: action.payload,
            };
        }

        case actionTypes.START_SHIFT_INITIATED: {
            return {
                ...state,
                loading: true,
                crudSuccess: false,
            };
        }
        case actionTypes.START_SHIFT_SUCCESS: {
            return {
                ...state,
                loading: false,
                crudSuccess: true,
                crudError: null,
            };
        }
        case actionTypes.START_SHIFT_FAILED: {
            return {
                ...state,
                loading: false,
                crudSuccess: false,
                crudError: action.payload,
            };
        }

        case actionTypes.ADD_CASH_DSHIFT_INIT: {
            return {
                ...state,
                loading: true,
                crudSuccess: false,
            };
        }
        case actionTypes.ADD_CASH_DSHIFT_SUCCESS: {
            return {
                ...state,
                loading: false,
                crudSuccess: true,
                crudError: null,
            };
        }
        case actionTypes.ADD_CASH_DSHIFT_FAILED: {
            return {
                ...state,
                loading: false,
                crudSuccess: false,
                crudError: action.payload,
            };
        }

        case actionTypes.RESET_ERRORS: {
            return {
                ...state,
                crudError: action.payload,
            }
        }
        default: {
            return {
                ...state,
            }  
        }

    }
}

export default CashierShift;