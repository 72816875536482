import * as actionTypes from "./types";
import useJwt from "@src/auth/jwt/useJwt";
import { showSuccessMsgToast, showErrorMsgToast } from '../../utility/functions/common';

export const handleListProductFetch = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.LIST_PRODUCT_INITIATED });
      const response = await useJwt.getProductList(data);
      dispatch({
        type: actionTypes.LIST_PRODUCT_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      if (err.response)
        dispatch({
          type: actionTypes.LIST_PRODUCT_FAILED,
          payload: err.response.data,
        });
    }
  };
};

export const handleCreateProduct = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.CREATE_PRODUCT_INITIATED });
      const response = await useJwt.createProduct(data);
      dispatch({
        type: actionTypes.CREATE_PRODUCT_SUCCESS,
        payload: response.data,
      });
      showSuccessMsgToast("Product created successfully!");
    } catch (err) {
      if (err.response)
        dispatch({
          type: actionTypes.CREATE_PRODUCT_FAILED,
          payload: err.response.data,
        });
    }
  };
};

export const handleUpdateProduct = (data, id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.UPDATE_PRODUCT_INITIATED });
      const response = await useJwt.updateProduct(data, id);
      dispatch({
        type: actionTypes.UPDATE_PRODUCT_SUCCESS,
        payload: response.data,
      });
      showSuccessMsgToast("Product updated successfully!");
    } catch (err) {
      if (err.response)
        dispatch({
          type: actionTypes.UPDATE_PRODUCT_FAILED,
          payload: err.response.data,
        });
    }
  };
};

export const handleDeleteProduct = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.DELETE_PRODUCT_INITIATED });
      const response = await useJwt.deleteProduct(id);
      dispatch({
        type: actionTypes.DELETE_PRODUCT_SUCCESS,
        payload: response.data,
      });
      showSuccessMsgToast("Product deleted successfully!");
    } catch (err) {
      if (err.response)
        dispatch({
          type: actionTypes.DELETE_PRODUCT_FAILED,
          payload: err.response.data,
        });
      showErrorMsgToast("Product deletion failed!");
    }
  };
};

export const resetErrors = () => ({ type: actionTypes.RESET_ERRORS });
