// ** start SHIFT **
export const START_SHIFT_INITIATED = "START_SHIFT_INITIATED";
export const START_SHIFT_SUCCESS = "START_SHIFT_SUCCESS";
export const START_SHIFT_FAILED = "START_SHIFT_FAILED";


export const ADD_CASH_DSHIFT_INIT = "ADD_CASH_DSHIFT_INIT";
export const ADD_CASH_DSHIFT_SUCCESS = "ADD_CASH_DSHIFT_SUCCESS";
export const ADD_CASH_DSHIFT_FAILED =  "ADD_CASH_DSHIFT_FAILED";


export const FETCH_CASHIER_HISTORY_INIT = "FETCH_CASHIER_HISTORY_INIT";
export const FETCH_CASHIER_HISTORY_SUCCESS = "FETCH_CASHIER_HISTORY_SUCCESS";
export const FETCH_CASHIER_HISTORY_FAILED = "FETCH_CASHIER_HISTORY_FAILED";

// ** reset error
export const RESET_ERRORS = "RESET_ERRORS";
