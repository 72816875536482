import { UPDATE_PASSWORD_FAILED, UPDATE_PASSWORD_INITIATED, UPDATE_PASSWORD_SUCCESS, RESET_STATE } from "./types";

const initialState = {};

const passwordUpdateReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PASSWORD_INITIATED: {
      return {
        ...state,
        updatePasswordInProcess: true,
        success: false,
      };
    }
    case UPDATE_PASSWORD_SUCCESS: {
      return {
        ...state,
        updatePasswordInProcess: false,
        success: true,
        error: null,
      };
    }
    case UPDATE_PASSWORD_FAILED: {
      return {
        ...state,
        updatePasswordInProcess: false,
        success: false,
        error: action.payload,
      };
    }
    case RESET_STATE: {
      return {
        ...state,
        success: false,
        error: null,
      };
    }
    default: {
      return state;
    }
  }
};

export default passwordUpdateReducer;
