import * as actionTypes from "./types";

const initialState = {
  loading: false,
  success: false,
  error: null,
  listSmartGates: null,
  crudSuccess: false,
  crudError: null,
};

const listEventReducer = (state = initialState, action) => {
  switch (action.type) {
    // ** List Events  */
    case actionTypes.LIST_SMART_GATE_INITIATED: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.LIST_SMART_GATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        listSmartGates: action.payload,
        success: true,
        error: null,
      };
    }
    case actionTypes.LIST_SMART_GATE_FAILED: {
      return {
        ...state,
        loading: false,
        listSmartGates: null,
        success: false,
        error: action.payload,
      };
    }

    // ** Create SmartGate  */
    case actionTypes.CREATE_SMART_GATE_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.CREATE_SMART_GATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.CREATE_SMART_GATE_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ***** Update SmartGate
    case actionTypes.UPDATE_SMART_GATE_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.UPDATE_SMART_GATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.UPDATE_SMART_GATE_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** Delete SmartGate
    case actionTypes.DELETE_SMART_GATE_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.DELETE_SMART_GATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.DELETE_SMART_GATE_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** reset errors
    case actionTypes.RESET_ERRORS: {
      return {
        ...state,
        crudError: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default listEventReducer;
