// ** create Product
export const CREATE_PRODUCT_INITIATED = "CREATE_PRODUCT_INITIATED";
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS";
export const CREATE_PRODUCT_FAILED = "CREATE_PRODUCT_FAILED";

// ** update Product
export const UPDATE_PRODUCT_INITIATED = "UPDATE_PRODUCT_INITIATED";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_FAILED = "UPDATE_PRODUCT_FAILED";

// ** delete Product
export const DELETE_PRODUCT_INITIATED = "DELETE_PRODUCT_INITIATED";
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS";
export const DELETE_PRODUCT_FAILED = "DELETE_PRODUCT_FAILED";

// ** list Products
export const LIST_PRODUCT_INITIATED = "LIST_PRODUCT_INITIATED";
export const LIST_PRODUCT_SUCCESS = "LIST_PRODUCT_SUCCESS";
export const LIST_PRODUCT_FAILED = "LIST_PRODUCT_FAILED";

// ** reset Errors
export const RESET_ERRORS = "RESET_ERRORS";
