import * as actionTypes from "./types";

const initialState = {
  cardListInProgress: false,
  listCards: null,
  error: null,
  sucess: false,
  crudSuccess: false,
  crudError: null,
};

const cardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_CARD_INITIATED:
      return {
        cardListInProgress: true,
      };
    case actionTypes.LIST_CARD_SUCCESS:
      return {
        ...state,
        cardListInProgress: false,
        card_list: action.payload,
        error: null,
      };
    case actionTypes.LIST_CARD_FAILED:
      return {
        ...state,
        cardListInProgress: false,
        error: action.payload,
        card_list: {},
      };

    // ** Create Card  */
    case actionTypes.CREATE_CARD_INITIATED: {
      return {
        ...state,
        cardListInProgress: true,
        crudSuccess: false,
      };
    }
    case actionTypes.CREATE_CARD_SUCCESS: {
      return {
        ...state,
        cardListInProgress: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.CREATE_CARD_FAILED: {
      return {
        ...state,
        cardListInProgress: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** Update Card
    case actionTypes.UPDATE_CARD_INITIATED: {
      return {
        ...state,
        cardListInProgress: true,
        crudSuccess: false,
      };
    }
    case actionTypes.UPDATE_CARD_SUCCESS: {
      return {
        ...state,
        cardListInProgress: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.UPDATE_CARD_FAILED: {
      return {
        ...state,
        cardListInProgress: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** Delete Event
    case actionTypes.DELETE_CARD_INITIATED: {
      return {
        ...state,
        cardListInProgress: true,
        crudSuccess: false,
      };
    }
    case actionTypes.DELETE_CARD_SUCCESS: {
      return {
        ...state,
        cardListInProgress: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.DELETE_CARD_FAILED: {
      return {
        ...state,
        cardListInProgress: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** reset errors
    case actionTypes.RESET_ERRORS: {
      return {
        ...state,
        crudError: action.payload,
      };
    }
    default:
      return state;
  }
};

export default cardReducer;
