export const LIST_ADMIN_INITIATED = "LIST_ADMIN_INITIATED";
export const LIST_ADMIN_SUCCESS = "LIST_ADMIN_SUCCESS";
export const LIST_ADMIN_FAILED = "LIST_ADMIN_FAILED";

//** create admin  */s
export const CREATE_ADMIN_INITIATED = "CREATE_ADMIN_INITIATED";
export const CREATE_ADMIN_SUCCESS = "CREATE_ADMIN_SUCCESS";
export const CREATE_ADMIN_FAILED = "CREATE_ADMIN_FAIL";

// ** get Admin */
export const GET_ADMIN_INITIATED = "GET_ADMIN_INITIATED";
export const GET_ADMIN_SUCCESS = "GET_ADMIN_SUCCESS";
export const GET_ADMIN_FAILED = "GET_ADMIN_FAIL";

// ** UPDATE Admin */
export const UPDATE_ADMIN_INITIATED = "UPDATE_ADMIN_INITIATED";
export const UPDATE_ADMIN_SUCCESS = "UPDATE_ADMIN_SUCCESS";
export const UPDATE_ADMIN_FAILED = "UPDATE_ADMIN_FAIL";

// ** Delete Admin */
export const DELETE_ADMIN_INITIATED = "DELETE_ADMIN_INITIATED";
export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_FAILED = "DELETE_ADMIN_FAIL";

// ** reset error
export const RESET_ERRORS = "RESET_ERRORS";
