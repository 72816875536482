import moment from "moment";
import ReactPaginate from "react-paginate";
import { ChevronDown } from "react-feather";
import CustomSpinner from "../common/Spinner";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect, useState, memo } from "react";
import { fetchCashierHistory } from "../../redux/shift/shiftActions";
import { Label, Input, Row, Col, Card, CardTitle, CardBody, CardHeader } from "reactstrap";

const ShiftHistory = ({ id, setCashierId }) => {
  const [searchKeyword, setSearchKeyword] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const dispatch = useDispatch();
  const { loading, cashierHistory } = useSelector((state) => state.shift);

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "searchKeyword") setSearchKeyword(value);
  };

  const advSearchColumns = [
    {
      name: "Name",
      minWidth: "150px",
      sortable: true,

      selector: (row) => row.user?.name || 'N/A',
    },
    {
      name: "Username",
      minWidth: "150px",
      sortable: true,
      selector: (row) => row.user?.username || 'N/A',
    },
    {
      name: "Cash",
      minWidth: "150px",
      sortable: true,
      selector: (row) => row.cash || 'N/A',
    },
    {
      name: "Cash Type",
      minWidth: "150px",
      sortable: true,
      selector: (row) => row.cashType || 'N/A',
    },
    {
      name: "Created At",
      minWidth: "230px",
      sortable: true,
      selector: (row) => moment(row.createdAt).format("DD MMM YYYY, hh:mm A"),
    },
  ];

  const handlePagination = (page) => {
    setPage(page.selected + 1);
  };

  // ** Custom Pagination
  const CustomPagination = () => (
    <ReactPaginate
      previousLabel={""}
      nextLabel={""}
      breakLabel="..."
      pageCount={cashierHistory?.pages || 1}
      marginPagesDisplayed={2}
      pageRangeDisplayed={2}
      activeClassName="active"
      forcePage={page !== 0 ? page - 1 : 0}
      onPageChange={handlePagination}
      pageClassName={"page-item"}
      nextLinkClassName={"page-link"}
      nextClassName={"page-item next"}
      previousClassName={"page-item prev"}
      previousLinkClassName={"page-link"}
      pageLinkClassName={"page-link"}
      breakClassName="page-item"
      breakLinkClassName="page-link"
      containerClassName={
        "pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1 mr-2"
      }
    />
  );

  useEffect(() => {
    dispatch(fetchCashierHistory({ id, 
        page,
        limit,
      })
    );
  }, [searchKeyword, page, limit, id]);

  useEffect(() => {
    return () => setCashierId("");
  }, []);

  return (
    <Fragment>
      <Card>
        <CardHeader className="border-bottom">
          <CardTitle tag="h4">Cashier Shift History</CardTitle>
        </CardHeader>
        <CardBody>
          <Row className="mt-1 mb-50 d-flex justify-content-between">
            <Col sm="2" md="2">
              <div className="d-flex align-items-center">
                <Label for="sort-select">Show</Label>
                <Input
                  className="dataTable-select show-limit"
                  type="select"
                  id="sort-select"
                  value={limit}
                  onChange={(e) => {
                    setLimit(e.target.value);
                    setPage(1);
                  }}
                >
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </Input>
                <Label for="sort-select">entries</Label>
              </div>
            </Col>
          </Row>
          <Row className="mt-1 mb-50"></Row>
          <Row className="mt-1 mb-50">
            <Col lg="3" md="6" className="mb-1">
              <Input
                placeholder="Search"
                type="text"
                id="searchKeyword"
                name="searchKeyword"
                value={searchKeyword}
                onChange={onChangeHandler}
              />
            </Col>
          </Row>
        </CardBody>
        {loading ? (
          <CustomSpinner />
        ) : cashierHistory?.results?.length ? (
          <div className="react-dataTable">
            <DataTable
              noHeader
              pagination
              columns={advSearchColumns}
              className="react-dataTable"
              sortIcon={<ChevronDown size={10} />}
              paginationDefaultPage={page + 1}
              paginationComponent={CustomPagination}
              paginationPerPage={limit}
              data={cashierHistory?.results}
            />
            <div>
              <span id="total_records">
                <b>Total Records:</b> {cashierHistory?.total || 0}
              </span>
            </div>
          </div>
        ) : (
          <Card className="mt-3">
            <CardHeader className="d-flex align-items-center justify-content-center">
              No Record Found!
            </CardHeader>
          </Card>
        )}
      </Card>
    </Fragment>
  );
};

export default memo(ShiftHistory);