import * as actionTypes from "./types";

const initialState = {
  loading: false,
  success: false,
  error: null,
  listCategories: null,
  listParentCategories: null,
  crudSuccess: false,
  crudError: null,
};

const listCategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    // ** List Categories  */
    case actionTypes.LIST_CATEGORY_INITIATED: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.LIST_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        listCategories: action.payload,
        success: true,
        error: null,
      };
    }
    case actionTypes.LIST_CATEGORY_FAILED: {
      return {
        ...state,
        loading: false,
        listCategories: null,
        success: false,
        error: action.payload,
      };
    }

    // ** List Parent Categories  */
    case actionTypes.LIST_PARENT_CATEGORY_INITIATED: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.LIST_PARENT_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        listParentCategories: action.payload,
        success: true,
        error: null,
      };
    }
    case actionTypes.LIST_PARENT_CATEGORY_FAILED: {
      return {
        ...state,
        loading: false,
        listParentCategories: null,
        success: false,
        error: action.payload,
      };
    }

    // ** Create Category  */
    case actionTypes.CREATE_CATEGORY_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.CREATE_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.CREATE_CATEGORY_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ***** Update Category
    case actionTypes.UPDATE_CATEGORY_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.UPDATE_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.UPDATE_CATEGORY_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** Delete Category
    case actionTypes.DELETE_CATEGORY_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.DELETE_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.DELETE_CATEGORY_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** reset errors
    case actionTypes.RESET_ERRORS: {
      return {
        ...state,
        crudError: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default listCategoryReducer;
