import * as actionTypes from "./types";
import useJwt from "@src/auth/jwt/useJwt";
import { showSuccessMsgToast, showErrorMsgToast } from '../../utility/functions/common';

export const handleListCollectionFetch = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.LIST_COLLECTION_INITIATED });
      const response = await useJwt.getCollectionToolList(data);
      dispatch({
        type: actionTypes.LIST_COLLECTION_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      if (err.response) {
        dispatch({
          type: actionTypes.LIST_COLLECTION_FAILED,
          payload: err.response.data,
        });
      }
    }
  };
};

//** list RFID Tool  */
export const handleListRFIDFetch = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.LIST_RFID_INITIATED });
      const response = await useJwt.getRfidToolList(data);
      dispatch({ type: actionTypes.LIST_RFID_SUCCESS, payload: response.data });
    } catch (err) {
      if (err.response) {
        dispatch({
          type: actionTypes.LIST_RFID_FAILED,
          payload: err.response.data,
        });
      }
    }
  };
};

// ** UPDATE product stats list **
export const handleUpdateCollectionTool = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.UPDATE_COLLECTION_INITIATED });
      const response = await useJwt.updateCollectionTool(data);
      dispatch({
        type: actionTypes.UPDATE_COLLECTION_SUCCESS,
        payload: response,
      });
      showSuccessMsgToast("Operation started Successfully");
    } catch (err) {
      if (err.response) {
        dispatch({
          type: actionTypes.UPDATE_COLLECTION_FAILED,
          payload: err.response.data,
        });
        showErrorMsgToast("Update Failed Something Wrong");
      }
    }
  };
};

// update RFID tool
export const handleUpdateRFIDTool = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.UPDATE_RFID_INITIATED });
      const response = await useJwt.updateRFIDTool(data);
      dispatch({ type: actionTypes.UPDATE_RFID_SUCCESS, payload: response });
      showSuccessMsgToast("Operation Started Successfully");
    } catch (err) {
      if (err.response) {
        dispatch({
          type: actionTypes.UPDATE_RFID_FAILED,
          payload: err.response.data,
        });
        showErrorMsgToast("Update Failed Something Wrong");
      }
    }
  };
};

//** RESET Errors
export const resetErrors = () => ({ type: actionTypes.RESET_ERRORS });
