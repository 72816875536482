import toast from "react-hot-toast";

export const getObjectKeyName = (array) => {
  const result = [];
  for (let i = 0; i < array.length; i++) {
    result.push({ name: array[i] });
  }
  return result;
};

export const removeDuplicates = (myArr, prop) => {
  return myArr.filter((obj, pos, arr) => {
    return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
  });
};

export const getCategoriesSorted = (array) => {
  const initial_categories = [];
  const subcategories = [];
  for (let i = 0; i < array.length; i++) {
    if (array[i].parent) {
      subcategories.push({
        parent_id: array[i].parent._id,
        parent_name: array[i].parent.name,
        description: array[i].description,
        createdAt: array[i].createdAt,
        name: array[i].name,
      });
      initial_categories.push({
        id: array[i].parent._id,
        name: array[i].parent.name,
      });
    }
  }

  const categories = removeDuplicates(initial_categories, "id");
  return [{ categories }, { subcategories }];
};

export const showSuccessToast = (payload, msg) => {
  toast.success(msg);
  return payload;
};

export const showErrorsToast = (arr) => {
  for (let i = 0; i < arr.length; i++) {
    toast.error(arr[i].msg);
  }
  return arr;
};

export const showSuccessMsgToast = (msg) => {
  toast.success(msg);
};

export const showErrorMsgToast = (msg) => {
  toast.error(msg);
};

export const waitFor = (cb, delay) => {
  setTimeout(() => {
    cb();
  }, delay);
};

export const ChangeRowColor = (cash, i) => {
  if (cash > 2000) {
    return "table-danger";
  }
  if (cash >= 1000 && cash <= 2000) {
    return "table-warning";
  }
  if (cash < 1000) {
    return "table-success";
  }
};

export const getDateTime = (date) => {
  const now = new Date(date);
  const year = now.getFullYear();
  let month = now.getMonth() + 1;
  let day = now.getDate();
  let hour = now.getHours();
  let minute = now.getMinutes();
  if (month.toString().length === 1) {
    month = `0${month}`;
  }
  if (day.toString().length === 1) {
    day = `0${day}`;
  }
  if (hour.toString().length === 1) {
    hour = `0${hour}`;
  }
  if (minute.toString().length === 1) {
    minute = `0${minute}`;
  }
  const dateTime = new Date(`${month} ${year} ${day} ${hour}:${minute}`);
  return dateTime;
};
