import * as actionTypes from "./types";
import useJwt from "@src/auth/jwt/useJwt";
import { showSuccessMsgToast, showErrorMsgToast } from '../../utility/functions/common';

// get location list
export const handleListLocationFetch = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.LIST_LOCATION_INITIATED });
      const response = await useJwt.getLocationList(data);
      dispatch({
        type: actionTypes.LIST_LOCATION_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      if (err.response) {
        dispatch({
          type: actionTypes.LIST_LOCATION_FAILED,
          payload: err.response.data,
        });
      }
    }
  };
};

// get location extra
export const handleGetExtra = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.LOCATION_EXTRA_INITIATED });
      const response = await useJwt.getLocationExtras();
      dispatch({
        type: actionTypes.LOCATION_EXTRA_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      if (err.response) {
        dispatch({
          type: actionTypes.LOCATION_EXTRA_FAILED,
          payload: err.response.data,
        });
      }
    }
  };
};

// create location action
export const createLocation = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.CREATE_LOCATION_INITIATED });
      const resposne = await useJwt.createLocation(data);
      dispatch({
        type: actionTypes.CREATE_LOCATION_SUCCESS,
        payload: resposne.data,
      });
      showSuccessMsgToast("Location created successfully!");
    } catch (err) {
      if (err.response) {
        dispatch({
          type: actionTypes.CREATE_LOCATION_FAILED,
          payload: err.response.data,
        });
      }
    }
  };
};

// update location
export const updateLocation = (data, id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.UPDATE_LOCATION_INITIATED });
      const response = await useJwt.updateLocation(data, id);
      dispatch({
        type: actionTypes.UPDATE_LOCATION_SUCCESS,
        payload: response.data,
      });
      showSuccessMsgToast("Location updated successfully!");
    } catch (err) {
      if (err.response) {
        dispatch({
          type: actionTypes.UPDATE_LOCATION_FAILED,
          payload: err.response.data,
        });
      }
    }
  };
};

// delete location
export const deleteLocation = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.DELETE_LOCATION_INITIATED });
      const response = await useJwt.deleteLocation(id);
      dispatch({
        type: actionTypes.DELETE_LOCATION_SUCCESS,
        payload: response.data,
      });
      showSuccessMsgToast("Location deleted successfully!");
    } catch (err) {
      if (err.response) {
        dispatch({
          type: actionTypes.DELETE_LOCATION_FAILED,
          payload: err.response.data,
        });
        showErrorMsgToast("Location deletion failed!");
      }
    }
  };
};

// reset Errors
export const resetErrors = () => ({ type: actionTypes.RESET_ERRORS });
