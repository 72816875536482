import * as actionTypes from "./types";

const initialState = {
  loading: false,
  success: false,
  error: null,
  listCollection: null,
  listRFID: null,
  crudSuccess: false,
  crudError: null,
};

const CorrectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_COLLECTION_INITIATED: {
      return {
        ...state,
        loading: true,
      };
    }

    case actionTypes.LIST_COLLECTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        listCollection: action.payload,
        success: true,
        error: null,
      };
    }

    case actionTypes.LIST_COLLECTION_FAILED: {
      return {
        ...state,
        loading: false,
        listCollection: null,
        success: false,
        error: action.payload,
      };
    }

    // ** %RFID Toool list
    case actionTypes.LIST_RFID_INITIATED: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.LIST_RFID_SUCCESS: {
      return {
        ...state,
        loading: false,
        listRFID: action.payload,
        success: true,
        error: null,
      };
    }
    case actionTypes.LIST_RFID_FAILED: {
      return {
        ...state,
        loading: false,
        listRFID: null,
        success: false,
        error: action.payload,
      };
    }

    // ** Update RFID stats
    case actionTypes.UPDATE_RFID_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.UPDATE_RFID_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.UPDATE_RFID_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** Update collection stats
    case actionTypes.UPDATE_COLLECTION_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.UPDATE_COLLECTION_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.UPDATE_COLLECTION_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** reset errors
    case actionTypes.RESET_ERRORS: {
      return {
        ...state,
        crudError: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default CorrectionReducer;
