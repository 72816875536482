import * as actionTypes from "./types";

const initailstate = {
  settings: null,
  loading: false,
  error: null,
  crudError: null,
  crudLoading: false,
};

const settingReducer = (state = initailstate, action) => {
  switch (action.type) {
    case actionTypes.GET_SETTINGS_INITIATED: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.GET_SETTINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        settings: action.payload.setting,
      };
    }
    case actionTypes.GET_SETTINGS_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }
    case actionTypes.UPDATE_SETTINGS_INITIATED: {
      return {
        ...state,
        crudLoading: true,
        crudError: null,
      };
    }
    case actionTypes.UPDATE_SETTINGS_SUCCESS: {
      return {
        ...state,
        settings: action.payload.setting,
        crudError: null,
        crudLoading: false,
      };
    }
    case actionTypes.UPDATE_SETTINGS_FAILED: {
      return {
        ...state,
        crudError: action.payload,
        crudLoading: false,
      };
    }
    default:
      return state;
  }
};

export default settingReducer;
