import { getUserData } from "@utils";
import { Outlet } from "react-router-dom";
import Layout from "@layouts/VerticalLayout";
import navigation from "@src/navigation/vertical";
import { Dash, Location, Cashier, Drink, Event } from "../assets/images/sidebar";

const VerticalLayout = () => {
  const user = getUserData();
  const adminNavigation = [
    {
      id: "dashboard",
      title: "Dashboard",
      icon: <Dash size={20} />,
      action: "read",
      resource: "admin",
      navLink: "/dashboard",
    },
    {
      id: "location",
      title: "Locations",
      icon: <Location size={20} />,
      action: "read",
      resource: "admin",
      navLink: "/locations",
    },
    {
      id: "cashier",
      title: "Cashiers",
      icon: <Cashier />,
      action: "read",
      resource: "admin",
      navLink: "/cashiers",
    },
    {
      id: "drink",
      title: "Drink/Food Products",
      icon: <Drink />,
      children: [
        {
          id: "category",
          title: "Categories",
          action: "read",
          resource: "admin",
          navLink: "/categories",
        },
        {
          id: "group",
          title: "Groups",
          action: "read",
          resource: "admin",
          navLink: "/groups",
        },
        {
          id: "kitchen",
          title: "Kitchens",
          action: "read",
          resource: "admin",
          navLink: "/kitchens",
        },
        {
          id: "products",
          title: "Products",
          action: "read",
          resource: "admin",
          navLink: "/products",
        },
      ],
    },
    {
      id: "event",
      title: "Events",
      action: "read",
      resource: "admin",
      icon: <Event />,
      navLink: "/events",
    },
    {
      id: "event",
      title: "Events",
      action: "read",
      resource: "admin",
      icon: <Event />,
      navLink: "/events",
    },
  ];
  return (
    <Layout
      menuData={
        user?.role === "superadmin"
          ? navigation
          : user?.role === "admin" && adminNavigation
      }
    >
      <Outlet />
    </Layout>
  );
};

export default VerticalLayout;
