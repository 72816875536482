// ** list kitchen
export const LIST_KITCHEN_INITIATED = "LIST_KITCHEN_INITIATED";
export const LIST_KITCHEN_SUCCESS = "LIST_KITCHEN_SUCCESS";
export const LIST_KITCHEN_FAILED = "LIST_KITCHEN_FAILED";

// ** Create Kitchen
export const CREATE_KITCHEN_INITIATED = "CREATE_KITCHEN_INITIATED";
export const CREATE_KITCHEN_SUCCESS = "CREATE_KITCHEN_SUCCESS";
export const CREATE_KITCHEN_FAILED = "CREATE_KITCHEN_FAILED";

// ** update Event
export const UPDATE_KITCHEN_INITIATED = "UPDATE_KITCHEN_INITIATED";
export const UPDATE_KITCHEN_SUCCESS = "UPDATE_KITCHEN_SUCCESS";
export const UPDATE_KITCHEN_FAILED = "UPDATE_KITCHEN_FAILED";

// **  Delete Event
export const DELETE_KITCHEN_INITIATED = "DELETE_KITCHEN_INITIATED";
export const DELETE_KITCHEN_SUCCESS = "DELETE_KITCHEN_SUCCESS";
export const DELETE_KITCHEN_FAILED = "DELETE_KITCHEN_FAILED";

// ** reset error
export const RESET_ERRORS = "RESET_ERRORS";
