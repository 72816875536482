import "flatpickr/dist/flatpickr.css";
import Flatpickr from "react-flatpickr";
import "react-widgets/scss/styles.scss";
import { Button } from "react-bootstrap";
import { ChevronDown } from "react-feather";
import CustomSpinner from "../common/Spinner";
import DataTable from "react-data-table-component";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Refresh, Edit } from "../../assets/images/table";
import { handleCashierListFetch } from "../../redux/user/userActions";
import { handleListLocationFetch } from "../../redux/location/locationActions";
import { handleListRFIDFetch, handleUpdateRFIDTool, resetErrors } from "../../redux/correctiontool/CorrectionToolActions";
import { Modal, ModalHeader, ModalBody, ModalFooter, Input, Label, Form, FormGroup, Row, Col, CardBody, CardTitle, Card, CardHeader } from "reactstrap";

const RfidTool = () => {
  const [modal, setModal] = useState(false);
  const [orderedAtFrom, setOrderedAtFrom] = useState();
  const [orderedAtTo, setOrderedAtTo] = useState();
  const [firstLoad, setFirstLoad] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");
  const [locationFilter, setLocationFilter] = useState("");
  const [cashierFilter, setCashierFilter] = useState("");
  const [loadData, setLoadData] = useState(false);
  const [rowData, setRowData] = useState(null);
  const { loading, listRFID, crudError, crudSuccess } = useSelector(
    (state) => state.tool
  );
  const { listCashiers } = useSelector((state) => state.cashier);
  const { listLocations } = useSelector((state) => state.location);
  const result = listRFID?.cashRFIDOrdersStats;
  const locationsList = listLocations?.results;
  const cashierList = listCashiers?.results;
  const [reducedAmount, setReducedAmount] = useState("");
  const [reducedPercentage, setReducedPercentage] = useState("");
  const dispatch = useDispatch();

  const handleclick = (row) => {
    setModal(true);
    setRowData(row);
    setErrorMsg("");
  };
  const toggle = () => {
    setModal(false);
    setReducedAmount("");
    setReducedPercentage("");
    setErrorMsg("");
    if (crudError) dispatch(resetErrors());
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    const RFIDObj = {};
    if (rowData.product === "RFID Purchases") {
      RFIDObj.operation = "purchase";
      RFIDObj.reducedQuantity = parseInt(reducedAmount);
    }
    if (rowData.product === "RFID Topups") {
      RFIDObj.operation = "topup";
      RFIDObj.reducedPercentage = parseFloat(reducedPercentage);
    }

    if (locationFilter) RFIDObj.location = locationFilter;
    if (cashierFilter) RFIDObj.user = cashierFilter;
    if (orderedAtFrom)
      RFIDObj.orderedAtFrom = new Date(orderedAtFrom).toISOString();
    if (orderedAtTo) RFIDObj.orderedAtTo = new Date(orderedAtTo).toISOString();
    if (
      RFIDObj.reducedPercentage === "" ||
      RFIDObj.reducedPercentage < 1 ||
      RFIDObj.reducedPercentage > 90
    ) {
      setErrorMsg("Invalid Reduced Percentage");
      return;
    } else if (
      RFIDObj.reducedQuantity === 0 ||
      RFIDObj.reducedQuantity === ""
    ) {
      setErrorMsg("Invalid Reduced Amount");
      return;
    } else {
      setErrorMsg("");
    }
    dispatch(handleUpdateRFIDTool(RFIDObj));
  };

  const advSearchColumns = [
    {
      name: "type",
      sortable: true,
      selector: (row) => row.product || "N/A",
    },
    {
      name: "Sold Quantity",
      sortable: true,
      selector: (row) => row?.totalOrders,
    },
    {
      name: "Total Sales Amount",
      sortable: true,
      selector: (row) => {
        return row?.totalSalesAmount && `€ ${row.totalSalesAmount?.toFixed(2)}`;
      },
    },
    {
      name: "Action",
      allowOverflow: true,
      cell: (row) => {
        return (
          <div className="d-flex">
            <span
              className="me-1"
              style={{ cursor: "pointer" }}
              onClick={() => handleclick(row)}
            >
              <Edit />
            </span>
          </div>
        );
      },
    },
  ];
  const clearDates = () => {
    setOrderedAtFrom(undefined);
    setOrderedAtTo(undefined);
  };

  useEffect(() => {
    const formattedDateFrom =
      orderedAtFrom && new Date(orderedAtFrom).toISOString();
    const formattedDateTo = orderedAtTo && new Date(orderedAtTo).toISOString();
    if (loadData)
      dispatch(
        handleListRFIDFetch({
          locationFilter,
          cashierFilter,
          orderedAtFrom: orderedAtFrom === undefined ? "" : formattedDateFrom,
          orderedAtTo: orderedAtTo === undefined ? "" : formattedDateTo,
        })
      );
    if (firstLoad) {
      dispatch(handleListLocationFetch({ page: 1, limit: 100 }));
      dispatch(handleCashierListFetch({ page: 1, limit: 100 }));
      setFirstLoad(false);
    }
  }, [orderedAtFrom, orderedAtTo, locationFilter, cashierFilter, loadData]);

  useEffect(() => {
    if (crudSuccess) {
      toggle();
      setErrorMsg("");
    }
  }, [crudSuccess]);

  return (
    <Fragment>
      <Card>
        <CardHeader className="border-bottom mb-5">
          <CardTitle tag="h4">RFID Stats List</CardTitle>
        </CardHeader>
        <CardBody>
          <Row className="mt-1 mb-50">
            <Col lg="4" md="6" className="mb-1">
              <Input
                id="locationFilter"
                name="locationFilter"
                type="select"
                value={locationFilter}
                onChange={(e) => {
                  setLocationFilter(e.target.value);
                  setLoadData(false);
                }}
              >
                <option value="">Select Location</option>
                {locationsList?.map((location, i) => (
                  <option key={i} value={location._id}>
                    {location.name}
                  </option>
                ))}
              </Input>
            </Col>
            <Col lg="4" md="6" className="mb-1">
              <Input
                id="cashierList"
                name="cashierList"
                type="select"
                value={cashierFilter}
                onChange={(e) => {
                  setCashierFilter(e.target.value);
                  setLoadData(false);
                }}
              >
                <option value="">Select Cashier</option>
                {cashierList?.map((loc, i) => (
                  <option key={i} value={loc._id}>
                    {loc.name}
                  </option>
                ))}
              </Input>
            </Col>
            <Col lg="4" md="6" className="mb-1">
              <Flatpickr
                value={orderedAtFrom}
                data-enable-time
                dateFormat="F j, Y h:i K"
                id="date-time-picker"
                placeholder="From"
                className="form-control"
                onChange={(date) => {
                  setOrderedAtFrom(date);
                  setLoadData(false);
                }}
                style={{ backgroundColor: "white", color: "#6e6b7b" }}
              />
            </Col>
            <Col lg="4" md="6" className="mb-1">
              <Flatpickr
                value={orderedAtTo}
                data-enable-time
                id="date-time-picker"
                placeholder="To"
                className="form-control custom-css-date-time-picker"
                onChange={(date) => {
                  setOrderedAtTo(date);
                  setLoadData(false);
                }}
                style={{ backgroundColor: "white", color: "#6e6b7b" }}
              />
            </Col>
            <Col lg="4" md="3" className="mb-1 d-flex">
              <div
                className="d-flex align-items-center justify-content-xl-start mr-sm-3 mb-xs-3"
                style={{ marginTop: "-5px" }}
              >
                <span
                  className="d-flex justify-content-center align-items-center"
                  onClick={clearDates}
                  style={{ cursor: "pointer" }}
                >
                  <span style={{ marginRight: "5px" }}>Reset</span> <Refresh />
                </span>
                <Button
                  disabled={false}
                  variant="primary"
                  className="p-1"
                  onClick={() => setLoadData(true)}
                  size="sm"
                  style={{ marginLeft: "13px" }}
                >
                  Load Data
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
        {loadData &&
          (loading ? (
            <CustomSpinner />
          ) : result?.length ? (
            <div className="react-dataTable">
              <DataTable
                noHeader
                columns={advSearchColumns}
                className="react-dataTable"
                sortIcon={<ChevronDown size={10} />}
                data={result}
              />
            </div>
          ) : (
            <Card className="mt-3">
              <CardHeader className="d-flex align-items-center justify-content-center">
                No Record Found!
              </CardHeader>
            </Card>
          ))}
      </Card>
      {/* report form */}
      <div>
        <Modal isOpen={modal} toggle={toggle} backdrop={false} centered={true}>
          <ModalHeader toggle={toggle}>
            {rowData?.product === "RFID Purchases"
              ? " Edit RFID Purchases"
              : "Edit RFID Topups"}
          </ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup className="add-mb">
                {rowData?.product === "RFID Purchases" ? (
                  <>
                    <Label for="cash" className="fw-bolder mt-1">
                      Total Quantity To Reduce Purchases
                    </Label>
                    <Input
                      type="number"
                      className="mt-2"
                      placeholder="20"
                      required
                      onChange={(e) => setReducedAmount(e.target.value)}
                    />
                    {
                      <p className="text-danger m-1">
                        {errorMsg ? errorMsg : ""}
                      </p>
                    }
                  </>
                ) : (
                  <>
                    <Label for="cash" className="fw-bolder mt-1">
                      Total Percentage To Reduce Topup Sales Amount
                    </Label>
                    <Input
                      type="number"
                      className="mt-2"
                      placeholder="20%"
                      required
                      onChange={(e) => setReducedPercentage(e.target.value)}
                    />
                    {
                      <p className="text-danger m-1">
                        {errorMsg ? errorMsg : ""}
                      </p>
                    }
                  </>
                )}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              type="submit"
              disabled={loading && true}
              onClick={handleUpdate}
            >
              {rowData?.product === "RFID Purchases"
                ? " Edit RFID Purchases"
                : "Edit RFID Topups"}
            </Button>
            <Button color="secondary" onClick={toggle}>
              Cancel
            </Button>
            {crudError && (
              <p className="text-danger">
                {crudError.details?.length &&
                typeof crudError.details[0] === "string"
                  ? `${crudError.msg}: ${crudError.details[0]}`
                  : crudError.details?.length &&
                    typeof crudError.details[0] === "object"
                  ? `${crudError.msg}: ${crudError.details[0].msg}`
                  : crudError?.msg}
              </p>
            )}
          </ModalFooter>
        </Modal>
      </div>
    </Fragment>
  );
};

export default RfidTool;
