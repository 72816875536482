import * as actionTypes from "./types";
import useJwt from "@src/auth/jwt/useJwt";
import { showSuccessMsgToast, showErrorMsgToast } from '../../utility/functions/common';

// ** List Cashier
export const handleCashierListFetch = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.LIST_CASHIER_INITIATED });
      const response = await useJwt.getCashierList(data);
      dispatch({
        type: actionTypes.LIST_CASHIER_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      if (err.response)
        dispatch({
          type: actionTypes.LIST_CASHIER_FAILED,
          payload: err.response.data,
        });
    }
  };
};

// **   CREATE Cashier
export const handleCreateCashier = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.CREATE_CASHIER_INITIATED });
      const response = await useJwt.createCashier(data);
      dispatch({
        type: actionTypes.CREATE_CASHIER_SUCCESS,
        payload: response.data,
      });
      showSuccessMsgToast("Cashier created successfully!");
    } catch (err) {
      if (err.response)
        dispatch({
          type: actionTypes.CREATE_CASHIER_FAILED,
          payload: err.response.data,
        });
    }
  };
};

// ** UPDATE CASHIER
export const handleUpdateCashier = (data, id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.UPDATE_CASHIER_INITIATED });
      const response = await useJwt.updateCashier(data, id);
      dispatch({
        type: actionTypes.UPDATE_CASHIER_SUCCESS,
        payload: response.data,
      });
      showSuccessMsgToast("Cashier updated successfully!");
    } catch (err) {
      if (err.response)
        dispatch({
          type: actionTypes.UPDATE_CASHIER_FAILED,
          payload: err.response.data,
        });
    }
  };
};

// ** DELETE CASHIER
export const handleDeleteCashier = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.DELETE_CASHIER_INITIATED });
      const response = await useJwt.deleteCashier(id);
      dispatch({
        type: actionTypes.DELETE_CASHIER_SUCCESS,
        payload: response.data,
      });
      showSuccessMsgToast("Cashier deleted successfully!");
    } catch (err) {
      if (err.response)
        dispatch({
          type: actionTypes.DELETE_CASHIER_FAILED,
          payload: err.response.data,
        });
      showErrorMsgToast("Cashier deletion failed!");
    }
  };
};

//** reset Errors */
export const resetErrors = () => ({ type: actionTypes.RESET_ERRORS });
