import { MAIN_SERVICE_URL } from "../../@core/auth/jwt/jwtDefaultConfig";
import {
  showErrorMsgToast,
  showSuccessMsgToast,
} from "../../utility/functions/common";

const ExportCsv = (endPoint, fileName, setLoading) => {
  if (setLoading !== undefined) {
    setLoading(true);
  }

  fetch(`${MAIN_SERVICE_URL}/${endPoint}`, {
    method: "GET",
    headers: {
      Authorization: localStorage.getItem("accessToken")
        ? `Bearer ${localStorage.getItem("accessToken")}`
        : `Bearer ${localStorage.getItem("refreshToken")}`,
      "Content-Type": "application/csv",
    },
  })
    .then((response) => response.blob())
    .then((blob) => {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${fileName}.csv`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      if (setLoading !== undefined) {
        setLoading(false);
      }
      showSuccessMsgToast("SuccessFully export data");
    })
    .catch((e) => {
      if (setLoading !== undefined) {
        setLoading(false);
      }
      showErrorMsgToast("Export Data Failed");
    });
};

export default ExportCsv;
