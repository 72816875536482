import * as actionTypes from "./types";

const initialState = {
  loading: false,
  success: false,
  error: null,
  listEvents: null,
  crudSuccess: false,
  crudError: null,
};

const listEventReducer = (state = initialState, action) => {
  switch (action.type) {
    // ** List Events  */
    case actionTypes.LIST_EVENT_INITIATED: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.LIST_EVENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        listEvents: action.payload,
        success: true,
        error: null,
      };
    }
    case actionTypes.LIST_EVENT_FAILED: {
      return {
        ...state,
        loading: false,
        listEvents: null,
        success: false,
        error: action.payload,
      };
    }

    // ** Create Event  */
    case actionTypes.CREATE_EVENT_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.CREATE_EVENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.CREATE_EVENT_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ***** Update Event
    case actionTypes.UPDATE_EVENT_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.UPDATE_EVENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.UPDATE_EVENT_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** Delete Event
    case actionTypes.DELETE_EVENT_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.DELETE_EVENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.DELETE_EVENT_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** reset errors
    case actionTypes.RESET_ERRORS: {
      return {
        ...state,
        crudError: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default listEventReducer;
