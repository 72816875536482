import { getUserData } from "@utils";
import { Ability } from "@casl/ability";
import { initialAbility } from "./initialAbility";

//  Read ability from localStorage
// * Handles auto fetching previous abilities if already logged in user
// ? You can update this if you store user abilities to more secure place
// ! Anyone can update localStorage so be careful and please update this
const user = getUserData();
let existingAbility;
if (user?.role === "superadmin") {
  existingAbility = [
    {
      action: "manage",
      resource: "superadmin",
    },
  ];
}
if (user?.role === "admin") {
  existingAbility = [
    {
      action: "read",
      resource: "admin",
    },
  ];
}

export default new Ability(existingAbility || initialAbility);
