export const initialAbility = [
  {
    action: "manage",
    subject: "superadmin",
  },
  {
    action: "read",
    subject: "admin",
  },
];

export const _ = undefined;
