import * as actionTypes from "./types";

const initailstate = {
  loading: false,
  success: false,
  error: null,
  listLocations: null,
  crudSuccess: false,
  crudError: null,
  locationMenuList: [],
  locationPaymentMethodsList: [],
};

const listLocationReducer = (state = initailstate, action) => {
  switch (action.type) {
    case actionTypes.LIST_LOCATION_INITIATED: {
      return { ...state, loading: true };
    }
    case actionTypes.LIST_LOCATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        listLocations: action.payload,
        success: true,
        error: null,
      };
    }
    case actionTypes.LIST_LOCATION_FAILED: {
      return {
        ...state,
        loading: false,
        listLocations: null,
        success: false,
        error: action.payload,
      };
    }

    // ** Get location extra
    case actionTypes.LOCATION_EXTRA_INITIATED: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.LOCATION_EXTRA_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        locationMenuList: action.payload?.menus || [],
        locationPaymentMethodsList: action.payload?.paymentMethods || [],
        error: null,
      };
    }
    case actionTypes.LOCATION_EXTRA_FAILED: {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    }

    // ** create location
    case actionTypes.CREATE_LOCATION_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.CREATE_LOCATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.CREATE_LOCATION_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** update location
    case actionTypes.UPDATE_LOCATION_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.UPDATE_LOCATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.UPDATE_LOCATION_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** delete location
    case actionTypes.DELETE_LOCATION_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.DELETE_LOCATION_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.DELETE_LOCATION_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** reset errors
    case actionTypes.RESET_ERRORS: {
      return {
        ...state,
        crudError: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default listLocationReducer;
