export const LIST_SMART_GATE_INITIATED = "LIST_SMART_GATE_INITIATED";
export const LIST_SMART_GATE_SUCCESS = "LIST_SMART_GATE_SUCCESS";
export const LIST_SMART_GATE_FAILED = "LIST_SMART_GATE_FAILED";

// ** create Event
export const CREATE_SMART_GATE_INITIATED = "CREATE_SMART_GATE_INITIATED";
export const CREATE_SMART_GATE_SUCCESS = "CREATE_SMART_GATE_SUCCESS";
export const CREATE_SMART_GATE_FAILED = "CREATE_SMART_GATE_FAILED";

// ** update Event
export const UPDATE_SMART_GATE_INITIATED = "UPDATE_SMART_GATE_INITIATED";
export const UPDATE_SMART_GATE_SUCCESS = "UPDATE_SMART_GATE_SUCCESS";
export const UPDATE_SMART_GATE_FAILED = "UPDATE_SMART_GATE_FAILED";

// ** update Event
export const DELETE_SMART_GATE_INITIATED = "DELETE_SMART_GATE_INITIATED";
export const DELETE_SMART_GATE_SUCCESS = "DELETE_SMART_GATE_SUCCESS";
export const DELETE_SMART_GATE_FAILED = "DELETE_SMART_GATE_FAILED";

// ** reset error
export const RESET_ERRORS = "RESET_ERRORS";
