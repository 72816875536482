import * as actionTypes from "./types";

const initialState = {
  loading: false,
  success: false,
  error: null,
  listTables: null,
  crudSuccess: false,
  crudError: null,
};

const listTableReducer = (state = initialState, action) => {
  switch (action.type) {
    // ** List Tables  */
    case actionTypes.LIST_TABLE_INITIATED: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.LIST_TABLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        listTables: action.payload,
        success: true,
        error: null,
      };
    }
    case actionTypes.LIST_TABLE_FAILED: {
      return {
        ...state,
        loading: false,
        listTables: null,
        success: false,
        error: action.payload,
      };
    }

    // ** Create Table  */
    case actionTypes.CREATE_TABLE_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.CREATE_TABLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.CREATE_TABLE_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** Create Table Bulk */
    case actionTypes.CREATE_TABLE_BULK_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.CREATE_TABLE_BULK_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.CREATE_TABLE_BULK_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ***** Update Table
    case actionTypes.UPDATE_TABLE_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.UPDATE_TABLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.UPDATE_TABLE_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** Delete Table
    case actionTypes.DELETE_TABLE_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.DELETE_TABLE_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.DELETE_TABLE_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** reset errors
    case actionTypes.RESET_ERRORS: {
      return {
        ...state,
        crudError: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default listTableReducer;
