export const LIST_TABLE_INITIATED = "LIST_TABLE_INITIATED";
export const LIST_TABLE_SUCCESS = "LIST_TABLE_SUCCESS";
export const LIST_TABLE_FAILED = "LIST_TABLE_FAILED";

// ** create TABLE
export const CREATE_TABLE_INITIATED = "CREATE_TABLE_INITIATED";
export const CREATE_TABLE_SUCCESS = "CREATE_TABLE_SUCCESS";
export const CREATE_TABLE_FAILED = "CREATE_TABLE_FAILED";

// ** create TABLE BULK
export const CREATE_TABLE_BULK_INITIATED = "CREATE_TABLE_BULK_INITIATED";
export const CREATE_TABLE_BULK_SUCCESS = "CREATE_TABLE_BULK_SUCCESS";
export const CREATE_TABLE_BULK_FAILED = "CREATE_TABLE_BULK_FAILED";

// ** update TABLE
export const UPDATE_TABLE_INITIATED = "UPDATE_TABLE_INITIATED";
export const UPDATE_TABLE_SUCCESS = "UPDATE_TABLE_SUCCESS";
export const UPDATE_TABLE_FAILED = "UPDATE_TABLE_FAILED";

// ** update TABLE
export const DELETE_TABLE_INITIATED = "DELETE_TABLE_INITIATED";
export const DELETE_TABLE_SUCCESS = "DELETE_TABLE_SUCCESS";
export const DELETE_TABLE_FAILED = "DELETE_TABLE_FAILED";

// ** reset error
export const RESET_ERRORS = "RESET_ERRORS";
