export const LIST_CARD_INITIATED = "LIST_CARD_INITIATED";
export const LIST_CARD_SUCCESS = "LIST_CARD_SUCCESS";
export const LIST_CARD_FAILED = "LIST_CARD_FAILED";

// ** Create Kitchen
export const CREATE_CARD_INITIATED = "CREATE_CARD_INITIATED";
export const CREATE_CARD_SUCCESS = "CREATE_CARD_SUCCESS";
export const CREATE_CARD_FAILED = "CREATE_CARD_FAILED";

// ** update Event
export const UPDATE_CARD_INITIATED = "UPDATE_CARD_INITIATED";
export const UPDATE_CARD_SUCCESS = "UPDATE_CARD_SUCCESS";
export const UPDATE_CARD_FAILED = "UPDATE_CARD_FAILED";

// **  Delete Event
export const DELETE_CARD_INITIATED = "DELETE_CARD_INITIATED";
export const DELETE_CARD_SUCCESS = "DELETE_CARD_SUCCESS";
export const DELETE_CARD_FAILED = "DELETE_CARD_FAILED";

// ** reset error
export const RESET_ERRORS = "RESET_ERRORS";
