import * as actionTypes from "./types";

const initialState = {
  loading: false,
  success: false,
  error: null,
  listSaleStats: null,
  listSaleDash: null,
  cashierListSales: null,
  productListsales: null,
  houlryproductListsales: null,
  crudSuccess: false,
  crudError: null,
};

const listSalesStatsReducer = (state = initialState, action) => {
  switch (action.type) {
    //List sale Stats
    case actionTypes.LIST_SALES_INITIATED: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.LIST_SALES_SUCCESS: {
      return {
        ...state,
        loading: false,
        listSaleStats: action.payload,
        success: true,
        error: null,
      };
    }
    case actionTypes.LIST_SALES_FAILED: {
      return {
        ...state,
        loading: false,
        listklistSaleStatsitchen: null,
        success: false,
        error: action.payload,
      };
    }

    // List Sale dash
    case actionTypes.LIST_SALESDASH_INITIATED: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.LIST_SALESDASH_SUCCESS: {
      return {
        ...state,
        loading: false,
        listSaleDash: action.payload,
        success: true,
        error: null,
      };
    }
    case actionTypes.LIST_SALESDASH_FAILED: {
      return {
        ...state,
        loading: false,
        listSaleDash: null,
        success: false,
        error: action.payload,
      };
    }

    //List  cashier sales
    case actionTypes.LIST_CASHIERSALES_INITIATED: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.LIST_CASHIERSALES_SUCCESS: {
      return {
        ...state,
        loading: false,
        cashierListSales: action.payload,
        success: true,
        error: null,
      };
    }
    case actionTypes.LIST_CASHIERSALES_FAILED: {
      return {
        ...state,
        loading: false,
        cashierListSales: null,
        success: false,
        error: action.payload,
      };
    }

    // ** export userStats
    case actionTypes.EXPORT_USERSALES_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.EXPORT_USERSALES_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.EXPORT_USERSALES_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    //** Product sale list  */
    case actionTypes.LIST_PRODUCT_INITIATED: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.LIST_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
        productListsales: action.payload,
        success: true,
        error: null,
      };
    }
    case actionTypes.LIST_PRODUCT_FAILED: {
      return {
        ...state,
        loading: false,
        productListsales: null,
        success: false,
        error: action.payload,
      };
    }

    //** Hourly Product sale list  */
    case actionTypes.LIST_HOURLYPRO_INITIATED: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.LIST_HOURLYPRO_SUCCESS: {
      return {
        ...state,
        loading: false,
        houlryproductListsales: action.payload,
        success: true,
        error: null,
      };
    }
    case actionTypes.LIST_HOURLYPRO_FAILED: {
      return {
        ...state,
        loading: false,
        houlryproductListsales: null,
        success: false,
        error: action.payload,
      };
    }

    // ** reset errors
    case actionTypes.RESET_ERRORS: {
      return {
        ...state,
        crudError: action.payload,
      };
    }
    
    default: {
      return {
        ...state,
      };
    }
  }
};

export default listSalesStatsReducer;
