export const LIST_LOCATION_INITIATED = "LIST_LOCATION_INITIATED";
export const LIST_LOCATION_SUCCESS = "LIST_LOCATION_SUCCESS";
export const LIST_LOCATION_FAILED = "LIST_LOCATION_FAILED";

//** Location extra  */
export const LOCATION_EXTRA_INITIATED = "LOCATION_EXTRA_INITIATED";
export const LOCATION_EXTRA_SUCCESS = "LOCATION_EXTRA_SUCCESS";
export const LOCATION_EXTRA_FAILED = "LOCATION_EXTRA_FAILED";

// ** Create location
export const CREATE_LOCATION_INITIATED = "CREATE_LOCATION_INITIATED";
export const CREATE_LOCATION_SUCCESS = "CREATE_LOCATION_SUCCESS";
export const CREATE_LOCATION_FAILED = "CREATE_LOCATION_FAILED";

// update the location
export const UPDATE_LOCATION_INITIATED = "UPDATE_LOCATION_INITIATED";
export const UPDATE_LOCATION_SUCCESS = "UPDATE_LOCATION_SUCCESS";
export const UPDATE_LOCATION_FAILED = "UPDATE_LOCATION_FAILED";

// **  delete location
export const DELETE_LOCATION_INITIATED = "DELETE_LOCATION_INITIATED";
export const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
export const DELETE_LOCATION_FAILED = "DELETE_LOCATION_FAILED";

// **  reset errors
export const RESET_ERRORS = "RESET_ERRORS";
