import * as actionTypes from "./types";

const initialState = {
  loading: false,
  sucess: false,
  error: null,
  order_list: null,
};

const listOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_ORDER_INITIATED: {
      return { ...state, loading: true };
    }
    case actionTypes.LIST_ORDER_SUCCESS: {
      return {
        ...state,
        loading: false,
        order_list: action.payload,
        success: true,
        error: null,
      };
    }
    case actionTypes.LIST_ORDER_FAILED: {
      return {
        ...state,
        loading: false,
        success: false,
        order_list: null,
        error: action.payload,
      };
    }
    
    default: {
      return state;
    }
  }
};

export default listOrderReducer;
