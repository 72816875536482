export const LIST_EVENT_INITIATED = "LIST_EVENT_INITIATED";
export const LIST_EVENT_SUCCESS = "LIST_EVENT_SUCCESS";
export const LIST_EVENT_FAILED = "LIST_EVENT_FAILED";

// ** create Event
export const CREATE_EVENT_INITIATED = "CREATE_EVENT_INITIATED";
export const CREATE_EVENT_SUCCESS = "CREATE_EVENT_SUCCESS";
export const CREATE_EVENT_FAILED = "CREATE_EVENT_FAILED";

// ** update Event
export const UPDATE_EVENT_INITIATED = "UPDATE_EVENT_INITIATED";
export const UPDATE_EVENT_SUCCESS = "UPDATE_EVENT_SUCCESS";
export const UPDATE_EVENT_FAILED = "UPDATE_EVENT_FAILED";

// ** update Event
export const DELETE_EVENT_INITIATED = "DELETE_EVENT_INITIATED";
export const DELETE_EVENT_SUCCESS = "DELETE_EVENT_SUCCESS";
export const DELETE_EVENT_FAILED = "DELETE_EVENT_FAILED";

// ** reset error
export const RESET_ERRORS = "RESET_ERRORS";
