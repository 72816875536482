// ** list Tickets
export const LIST_TICKET_INITIATED = "LIST_TICKET_INITIATED";
export const LIST_TICKET_SUCCESS = "LIST_TICKET_SUCCESS";
export const LIST_TICKET_FAILED = "LIST_TICKET_FAILED";

// ** update Event
export const UPDATE_TICKET_INITIATED = "UPDATE_TICKET_INITIATED";
export const UPDATE_TICKET_SUCCESS = "UPDATE_TICKET_SUCCESS";
export const UPDATE_TICKET_FAILED = "UPDATE_TICKET_FAILED";

// **  Delete Event
export const DELETE_TICKET_INITIATED = "DELETE_TICKET_INITIATED";
export const DELETE_TICKET_SUCCESS = "DELETE_TICKET_SUCCESS";
export const DELETE_TICKET_FAILED = "DELETE_TICKET_FAILED";

// ** reset error
export const RESET_ERRORS = "RESET_ERRORS";
