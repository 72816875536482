import jwt_decode from "jwt-decode";
import useJwt from "@src/auth/jwt/useJwt";
import { getHomeRouteForLoggedInUser } from "@utils";
import { showSuccessMsgToast } from "../../utility/functions/common";
import { LOGIN_FAILED, LOGIN_INITIATED, LOGIN_SUCCESS, LOGOUT_SUCCESSFUL } from "./types";

export const initiateLogin = () => ({ type: LOGIN_INITIATED });
export const loginSuccess = (data) => ({ type: LOGIN_SUCCESS, payload: data });
export const loginFailed = (data) => ({ type: LOGIN_FAILED, payload: data });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESSFUL });

export const handleLogin = (data, navigate, updateAbility) => {
  return async (dispatch) => {
    try {
      dispatch(initiateLogin());
      const response = await useJwt.login(data);
      if (response.data) {
        const { accessToken } = response.data;
        useJwt.setToken(accessToken);
        const decoded = jwt_decode(accessToken);
        updateAbility(decoded.role);
        showSuccessMsgToast("SuccessFully Logged In!");
        dispatch(loginSuccess(decoded));
        navigate(getHomeRouteForLoggedInUser(decoded.role));
      }
    } catch (err) {
      if (err.response) dispatch(loginFailed(err.response.data));
    }
  };
};

export const handleLogout = (restAbility) => {
  return (dispatch) => {
    localStorage.removeItem("accessToken");
    dispatch(logoutSuccess());
    restAbility();
  };
};
