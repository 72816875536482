/* --------- Create Category  -------- */
export const CREATE_CATEGORY_INITIATED = "CREATE_CATEGORY_INITIATED";
export const CREATE_CATEGORY_SUCCESS = "CREATE_CATEGORY_SUCCESS";
export const CREATE_CATEGORY_FAILED = "CREATE_CATEGORY_FAILED";

/* --------- Update Category  ----------*/
export const UPDATE_CATEGORY_INITIATED = "UPDATE_CATEGORY_INITIATED";
export const UPDATE_CATEGORY_SUCCESS = "UPDATE_CATEGORY_SUCCESS";
export const UPDATE_CATEGORY_FAILED = "UPDATE_CATEGORY_FAILED";

/* --------- Delete Category  --------- */
export const DELETE_CATEGORY_INITIATED = "DELETE_CATEGORY_INITIATED";
export const DELETE_CATEGORY_SUCCESS = "DELETE_CATEGORY_SUCCESS";
export const DELETE_CATEGORY_FAILED = "DELETE_CATEGORY_FAILED";

/* --------- Get Category List ------ */
export const LIST_CATEGORY_INITIATED = "LIST_CATEGORY_INITIATED";
export const LIST_CATEGORY_SUCCESS = "LIST_CATEGORY_SUCCESS";
export const LIST_CATEGORY_FAILED = "LIST_CATEGORY_FAILED";

/* -------- Get Parent Categories List ---- */
export const LIST_PARENT_CATEGORY_INITIATED = "LIST_PARENT_CATEGORY_INITIATED";
export const LIST_PARENT_CATEGORY_SUCCESS = "LIST_PARENT_CATEGORY_SUCCESS";
export const LIST_PARENT_CATEGORY_FAILED = "LIST_PARENT_CATEGORY_FAILED";

/* ---------- Reset errors --------- */
export const RESET_ERRORS = "RESET_ERRORS";
