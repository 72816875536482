import * as actionTypes from "./types";

const initialState = {
    rfidcardListInProgress: false,
    listRfidCards: null,
    rfidDetails: null,
    rfidDetailsInProgress: false,
    error: null,
    sucess: false,
    crudSuccess: false,
    crudError: null,
};


const createRFIDReducer = (state = initialState, action) => {

    switch (action.type) {
        case actionTypes.LIST_CREATE_RFID_INITIATED: {
            return {
                ...state,
                rfidcardListInProgress: true,
            };
        }
        case actionTypes.LIST_CREATE_RFID_SUCCESS: {
            return {
                ...state,
                rfidcardListInProgress: false,
                listRfidCards: action.payload,
                success: true,
                error: null,
            };
        }
        case actionTypes.LIST_CREATE_RFID_FAILED: {
            return {
                ...state,
                rfidcardListInProgress: false,
                listRfidCards: null,
                success: false,
                error: action.payload,
            };
        }
        case actionTypes.CREATE_RFID_INITIATED:
            return {
                ...state,
                rfidcardListInProgress: true,
                crudSuccess: false,
            };
        case actionTypes.CREATE_RFID_SUCCESS: {
            return {
                ...state,
                rfidcardListInProgress: false,
                crudSuccess: true,
                crudError: null,
            };
        }

        case actionTypes.CREATE_RFID_FAILED: {
            return {
                ...state,
                rfidcardListInProgress: false,
                crudSuccess: false,
                crudError: action.payload,
            };
        }

        case actionTypes.FETCH_RFID_CARD_DETAILS_INITIATED: {
            return {
               ...state,
               rfidDetailsInProgress: true,
            };
        }

        case actionTypes.FETCH_RFID_CARD_DETAILS_SUCCESS: {
            return {
               ...state,
                rfidDetailsInProgress: false,
                rfidDetails: action.payload
            };
        }

        case actionTypes.FETCH_RFID_CARD_DETAILS_FAILED: {
            return {
               ...state,
                rfidDetailsInProgress: false,
                rfidDetails: null,
                error: action.payload,
            };
        }

        // ** reset errors
        case actionTypes.RESET_ERRORS: {
            return {
                ...state,
                crudError: action.payload,
            };
        }
        default:
            return state;

    }

}

export default createRFIDReducer