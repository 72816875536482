import moment from "moment";
import "flatpickr/dist/flatpickr.css";
import Flatpickr from "react-flatpickr";
import "react-widgets/scss/styles.scss";
import { Button } from "react-bootstrap";
import { ChevronDown } from "react-feather";
import CustomSpinner from "../common/Spinner";
import DataTable from "react-data-table-component";
import { Refresh } from "../../assets/images/table";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleListEventFetch } from "../../redux/event/eventActions";
import { handleListProductFetch } from "../../redux/product/productActions";
import { Row, Col, CardBody, CardTitle, Card, CardHeader, Input } from "reactstrap";
import { handleHourlyProductListSales } from "../../redux/saleStats/SaleStatsActions";

const HourlyProductSales = () => {
  const dispatch = useDispatch();
  const { listEvents } = useSelector((state) => state.event);
  const { listProducts } = useSelector((state) => state.product);
  const { loading, houlryproductListsales } = useSelector((state) => state.saleStats);

  const [date, setDate] = useState();
  const [event, setEvent] = useState();
  const [type, setType] = useState("");
  const [product, setProduct] = useState();
  const [firstLoad, setFirstLoad] = useState(true);
  const [loadData, setLoadData] = useState(false);

  const result = houlryproductListsales?.hourlyProductOrdersStats;
  const events = listEvents?.results;
  const products = listProducts?.results;

  const advSearchColumns = [
    {
      name: "slot",
      sortable: true,
      width: "200px",
      selector: (row) => row.slot || "N/A",
    },
    {
      name: "Total Cash Orders",
      width: "200px",
      sortable: true,
      selector: (row) => row.totalCashOrders,
    },
    {
      name: "Total Pin Orders",
      sortable: true,
      width: "200px",
      selector: (row) => row.totalPinOrders,
    },
    {
      name: "Total CashSales Amount",
      width: "230px",
      sortable: true,
      selector: (row) => {
        return `€ ${row.totalCashSalesAmount?.toFixed(2)}` || "N/A";
      },
    },
    {
      name: "Total PinSales Amount",
      width: "230px",
      sortable: true,
      selector: (row) => {
        return `€ ${row.totalPinSalesAmount?.toFixed(2)}` || "N/A";
      },
    },
    {
      name: "Cash Percentage",
      width: "200px",
      sortable: true,
      selector: (row) => {
        const totalOrder = row.totalCashOrders + row.totalPinOrders;
        const cashPercentage = (row.totalCashOrders / totalOrder || 0) * 100;
        return `${cashPercentage.toFixed(2)} %`;
      },
    },
    {
      name: "Pin Percentage",
      width: "200px",
      sortable: true,
      selector: (row) => {
        const totalOrder = row.totalCashOrders + row.totalPinOrders;
        const pinPercentage = (row.totalPinOrders / totalOrder || 0) * 100;
        return `${pinPercentage.toFixed(2)} %`;
      },
    },
  ];

  const clearDates = () => {
    setDate(undefined);
  };

  useEffect(() => {
    const inputDate = new Date(date);
    const formattedDate = moment(inputDate).format().split("T")[0];
    if (loadData)
      dispatch(
        handleHourlyProductListSales({
          type,
          event,
          date: formattedDate === undefined ? "" : formattedDate,
          product,
        })
      );
    if (firstLoad) {
      dispatch(handleListEventFetch({ page: 1, limit: 100 }));
      dispatch(handleListProductFetch({ page: 1, limit: 100 }));
    }
  }, [type, event, date, product, loadData]);

  return (
    <Fragment>
      <Card>
        <CardHeader className="border-bottom">
          <CardTitle tag="h4"> Hourly Product Sales List</CardTitle>
        </CardHeader>
        <CardBody>
          <Row className="mt-1 mb-50 d-flex justify-content-between">
            <Col
              className="mb-1 d-flex flex-grow-1 justify-content-end"
              sm="2"
              md="3"
            >
            </Col>
          </Row>
          <Row className="mt-1 mb-50"></Row>
          <Row className="mt-1 mb-50">
            <Col lg="4" md="6" className="mb-1">
              <Input
                type="select"
                id="type"
                value={type}
                name="type"
                onChange={(e) => {
                  setType(e.target.value);
                  setLoadData(false);
                }}
              >
                <option value="">Select Type</option>
                <option value="event">Event</option>
                <option value="product">Product</option>
              </Input>
            </Col>
            {type === "event" && (
              <Col lg="4" md="6" className="mb-1">
                <Input
                  id="event"
                  name="event"
                  type="select"
                  value={event}
                  onChange={(e) => setEvent(e.target.value)}
                >
                  <option value="">Select Event</option>
                  {events?.map((event, i) => (
                    <option key={i} value={event._id}>
                      {event.name}
                    </option>
                  ))}
                </Input>
              </Col>
            )}
            {type === "product" && (
              <Col lg="4" md="6" className="mb-1">
                <Input
                  id="product"
                  name="product"
                  type="select"
                  value={product}
                  onChange={(e) => setProduct(e.target.value)}
                >
                  <option value="">Select Product</option>
                  {products?.map((product, i) => (
                    <option key={i} value={product._id}>
                      {product.name}
                    </option>
                  ))}
                </Input>
              </Col>
            )}
            <Col lg="4" md="6" className="mb-1">
              <Flatpickr
                value={date}
                id="date-time-picker"
                placeholder="Select Date"
                className="form-control"
                onChange={(date) => {
                  setDate(date);
                  setLoadData(false);
                }}
                style={{ backgroundColor: "white", color: "#6e6b7b" }}
              />
            </Col>

            <Col lg="4" md="3" className="mb-1 d-flex">
              <div
                className="d-flex align-items-center justify-content-xl-start mr-sm-3 mb-xs-3"
                style={{ marginTop: "-5px" }}
              >
                <span
                  className="d-flex justify-content-center align-items-center"
                  onClick={clearDates}
                  style={{ cursor: "pointer" }}
                >
                  <span style={{ marginRight: "5px" }}>Reset</span> <Refresh />
                </span>
                <Button
                  disabled={false}
                  variant="primary"
                  className="p-1"
                  onClick={() => setLoadData(true)}
                  size="sm"
                  style={{ marginLeft: "13px" }}
                >
                  Load Data
                </Button>
              </div>
            </Col>
          </Row>
        </CardBody>
        {loadData &&
          (loading ? (
            <CustomSpinner />
          ) : result?.length ? (
            <div className="react-dataTable">
              <DataTable
                noHeader
                columns={advSearchColumns}
                className="react-dataTable"
                sortIcon={<ChevronDown size={10} />}
                data={result}
              />
            </div>
          ) : (
            <Card className="mt-3">
              <CardHeader className="d-flex align-items-center justify-content-center">
                No Record Found!
              </CardHeader>
            </Card>
          ))}
      </Card>
    </Fragment>
  );
};

export default HourlyProductSales;
