import * as actionTypes from "./types";

const initialState = {
  loading: false,
  success: false,
  error: null,
  listAdmin: null,
  crudSuccess: false,
  crudError: null,
};

const listAdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_ADMIN_INITIATED: {
      return { ...state, loading: true };
    }
    case actionTypes.LIST_ADMIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        success: true,
        listAdmin: action.payload,
        error: null,
      };
    }
    case actionTypes.LIST_ADMIN_FAILED: {
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload,
      };
    }
    // ** Create Admin  */
    case actionTypes.CREATE_ADMIN_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }

    case actionTypes.CREATE_ADMIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }

    case actionTypes.CREATE_ADMIN_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }
    // ** Update Admin
    case actionTypes.UPDATE_ADMIN_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }

    case actionTypes.UPDATE_ADMIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }

    case actionTypes.UPDATE_ADMIN_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }
    // ** Delete Admin
    case actionTypes.DELETE_ADMIN_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }

    case actionTypes.DELETE_ADMIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }

    case actionTypes.DELETE_ADMIN_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }
    // ** reset errors
    case actionTypes.RESET_ERRORS: {
      return {
        ...state,
        crudError: action.payload,
      };
    }
    default: {
      return { ...state };
    }
  }
};

export default listAdminReducer;
