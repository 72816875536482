import * as actionTypes from "./types";
import useJwt from "@src/auth/jwt/useJwt";
import { showSuccessMsgToast, showErrorMsgToast } from '../../utility/functions/common';


export const handleStartShift = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: actionTypes.START_SHIFT_INITIATED });
            const response = await useJwt.startCashierShift(data);
            dispatch({
                type: actionTypes.START_SHIFT_SUCCESS,
                payload: response.data,
            });
            showSuccessMsgToast("Shift started successfully")
        } catch (err) {
            console.log({err})
            if (err.response) {
                dispatch({
                    type: actionTypes.START_SHIFT_FAILED,
                    payload: err.response.data,
                });
            }
        }
    };
};    

//add Cash During shift  

export const handleAddDCashReceived = (data) => {
    return async (dispatch) => {
        try {
            dispatch({ type: actionTypes.ADD_CASH_DSHIFT_INIT });
            const response = await useJwt.addCashDShift(data);
            dispatch({
                type: actionTypes.ADD_CASH_DSHIFT_SUCCESS,
                payload: response.data,
            });
        } catch (err) {
            if (err.response) {
                dispatch({
                    type: actionTypes.ADD_CASH_DSHIFT_FAILED,
                    payload: err.response.data,
                });
                showErrorMsgToast(`${err.response.data.msg}. Start shift first then try again`)
            }
        }
    };
}

//Fetch Cashier history by id 

export const fetchCashierHistory = (data) => {
    return async (dispatch) => { 
        try {
            dispatch({ type: actionTypes.FETCH_CASHIER_HISTORY_INIT });
            const response = await useJwt.fetchCashierHistory(data);
            dispatch({
                type: actionTypes.FETCH_CASHIER_HISTORY_SUCCESS,
                payload: response.data,
            });
        } catch (err) {
            console.log({err})
            if (err.response) {
                dispatch({
                    type: actionTypes.FETCH_CASHIER_HISTORY_FAILED,
                    payload: err.response.data,
                });
            }
        }

    }
}
