import * as actionTypes from "./types";

const initialState = {
  loading: false,
  success: false,
  error: null,
  listTerminal: null,
  crudSuccess: false,
  crudError: null,
};

const listTerminalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LIST_TERMINAL_INITIATED: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.LIST_TERMINAL_SUCCESS: {
      return {
        ...state,
        loading: false,
        listTerminal: action.payload,
        success: true,
        error: null,
      };
    }
    case actionTypes.LIST_TERMINAL_FAILED: {
      return {
        ...state,
        loading: false,
        listTerminal: null,
        success: false,
        error: action.payload,
      };
    }

    default: {
      return {
        ...state,
      };
    }
  }
};

export default listTerminalReducer;
