import * as actionTypes from "./types";
import useJwt from "@src/auth/jwt/useJwt";
import { showSuccessMsgToast, showErrorMsgToast } from '../../utility/functions/common';

export const handleListEventFetch = (data = { limit: 100 }) => {
  console.log("data")
  console.log(data)
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.LIST_EVENT_INITIATED });
      const response = await useJwt.getEventList(data);
      dispatch({
        type: actionTypes.LIST_EVENT_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      if (err.response) {
        dispatch({
          type: actionTypes.LIST_EVENT_FAILED,
          payload: err.response.data,
        });
      }
    }
  };
};

// CREATE EVENT
export const handleCreateEvent = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.CREATE_EVENT_INITIATED });
      const response = await useJwt.createEvent(data);
      dispatch({
        type: actionTypes.CREATE_EVENT_SUCCESS,
        payload: response.data,
      });
      showSuccessMsgToast("Event created successfully!");
    } catch (err) {
      if (err.response) {
        dispatch({
          type: actionTypes.CREATE_EVENT_FAILED,
          payload: err.response.data,
        });
      }
    }
  };
};

// ** UPDATE EVENT
export const handleUpdateEvent = (data, id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.UPDATE_EVENT_INITIATED });
      const response = await useJwt.updateEvent(data, id);
      dispatch({ type: actionTypes.UPDATE_EVENT_SUCCESS, payload: response });
      showSuccessMsgToast("Event updated successfully!");
    } catch (err) {
      if (err.response) {
        dispatch({
          type: actionTypes.UPDATE_EVENT_FAILED,
          payload: err.response.data,
        });
      }
    }
  };
};

// ** DELETE EVENT
export const handleDeleteEvent = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.DELETE_EVENT_INITIATED });
      const response = await useJwt.deleteEvent(id);
      dispatch({ type: actionTypes.DELETE_EVENT_SUCCESS, payload: response });
      showSuccessMsgToast("Event deleted successfully!");
    } catch (err) {
      if (err.response) {
        dispatch({
          type: actionTypes.DELETE_EVENT_FAILED,
          payload: err.response.data,
        });
        showErrorMsgToast("Event deletion failed!");
      }
    }
  };
};

//** RESET Errors
export const resetErrors = () => ({ type: actionTypes.RESET_ERRORS });
