import * as actionTypes from "./types";
import useJwt from "@src/auth/jwt/useJwt";
import { showSuccessMsgToast } from "../../utility/functions/common";

export const getProfileInitiated = () => ({
  type: actionTypes.GET_PROFILE_INITIATED,
});

export const getProfileSuccess = (data) => ({
  type: actionTypes.GET_PROFILE_SUCCESS,
  payload: data,
});

export const getProfileFailed = (data) => ({
  type: actionTypes.GET_PROFILE_FAILED,
  payload: data,
});

export const handleGetProfile = () => {
  return async (dispatch) => {
    try {
      dispatch(getProfileInitiated());
      const response = await useJwt.getProfile();
      if (response.data) {
        dispatch(getProfileSuccess(response.data.admin));
      }
    } catch (err) {
      if (err.response) {
        dispatch(getProfileFailed(err.response.data));
      }
    }
  };
};

export const updateProfileInitiated = () => ({
  type: actionTypes.UPDATE_PROFILE_INITIATED,
});

export const updateProfileSuccess = (data) => ({
  type: actionTypes.UPDATE_PROFILE_SUCCESS,
  payload: data,
});

export const updateProfileFailed = (data) => ({
  type: actionTypes.UPDATE_PROFILE_FAILED,
  payload: data,
});

export const handleUpdateProfile = (data) => {
  return async (dispatch) => {
    try {
      dispatch(updateProfileInitiated());
      const response = await useJwt.updateProfile(data);
      if (response.data) {
        dispatch(updateProfileSuccess(response.data.admin));
        showSuccessMsgToast("Profile updated successfully!");
      }
    } catch (err) {
      if (err.response) {
        dispatch(updateProfileFailed(err.response.data));
      }
    }
  };
};

export const resetState = () => ({ type: actionTypes.RESET_STATE });
