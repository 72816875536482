import * as actionTypes from "./types";

const initialState = {
  loading: false,
  success: false,
  error: null,
  listAccessories: null,
  crudSuccess: false,
  crudError: null,
};

const listAccessoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    // ** List Accessories  */
    case actionTypes.LIST_ACCESSORIES_INITIATED: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.LIST_ACCESSORIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        listAccessories: action.payload,
        success: true,
        error: null,
      };
    }
    case actionTypes.LIST_ACCESSORIES_FAILED: {
      return {
        ...state,
        loading: false,
        listAccessories: null,
        success: false,
        error: action.payload,
      };
    }

    // ** Create Accessories  */
    case actionTypes.CREATE_ACCESSORIES_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.CREATE_ACCESSORIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.CREATE_ACCESSORIES_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** Create Accessories Bulk */
    case actionTypes.CREATE_ACCESSORIES_BULK_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.CREATE_ACCESSORIES_BULK_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.CREATE_ACCESSORIES_BULK_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ***** Update Accessories
    case actionTypes.UPDATE_ACCESSORIES_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.UPDATE_ACCESSORIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.UPDATE_ACCESSORIES_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** Delete Accessories
    case actionTypes.DELETE_ACCESSORIES_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.DELETE_ACCESSORIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.DELETE_ACCESSORIES_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** reset errors
    case actionTypes.RESET_ERRORS: {
      return {
        ...state,
        crudError: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default listAccessoriesReducer;
