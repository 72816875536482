import * as actionTypes from "./types";

const initialState = {
  loading: false,
  success: false,
  error: null,
  listProducts: null,
  crudSuccess: false,
  crudError: null,
};

const listProductReducer = (state = initialState, action) => {
  switch (action.type) {
    // ** List Events  */
    case actionTypes.LIST_PRODUCT_INITIATED: {
      return {
        ...state,
        loading: true,
      };
    }
    case actionTypes.LIST_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
        listProducts: action.payload,
        success: true,
        error: null,
      };
    }
    case actionTypes.LIST_PRODUCT_FAILED: {
      return {
        ...state,
        loading: false,
        listProducts: null,
        success: false,
        error: action.payload,
      };
    }

    // ** Create Product
    case actionTypes.CREATE_PRODUCT_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.CREATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.CREATE_PRODUCT_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** Update Product
    case actionTypes.UPDATE_PRODUCT_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.UPDATE_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.UPDATE_PRODUCT_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** Delete Product
    case actionTypes.DELETE_PRODUCT_INITIATED: {
      return {
        ...state,
        loading: true,
        crudSuccess: false,
      };
    }
    case actionTypes.DELETE_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
        crudSuccess: true,
        crudError: null,
      };
    }
    case actionTypes.DELETE_PRODUCT_FAILED: {
      return {
        ...state,
        loading: false,
        crudSuccess: false,
        crudError: action.payload,
      };
    }

    // ** reset errors
    case actionTypes.RESET_ERRORS: {
      return {
        ...state,
        crudError: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default listProductReducer;
