import ShiftHistory from "./ShiftHistory";
import { ChevronDown } from "react-feather";
import ReactPaginate from "react-paginate";
import CustomSpinner from "../common/Spinner";
import ExportShift from "../common/ExportShift";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { AddPlus } from "../../assets/images/sidebar";
import { handleCashierListFetch  } from "../../redux/user/userActions";
import { handleListLocationFetch } from "../../redux/location/locationActions";
import { Fragment, useEffect, useState, useRef, useLayoutEffect } from "react";
import { handleAddDCashReceived, handleStartShift } from "../../redux/shift/shiftActions";
import { Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Form, Label, Input, Button, Row, Col, Badge, Card, CardTitle, CardBody, CardHeader, Spinner } from "reactstrap";

const CashierShift = () => {

    const [locationFilter, setLocationFilter] = useState("");
    const [searchKeyword, setSearchKeyword] = useState("");
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [firstLoad, setFirstLoad] = useState(true);
    const [showModal, setShowModal] = useState(false)
    const [errorMsg, setErrorMsg] = useState("");
    const [cashGiven, setCashGiven] = useState(1);
    const [cashType, setCashType] = useState('')
    const [cashiers, setCashiers] = useState()
    const [cashierId, setCashierId] = useState("")
    const [cashierID, setCashierID] = useState("")
    const [checkNull, setCheckNull] = useState(false)
    const [checkExport, setCheckExport] = useState(false)

    const dispatch = useDispatch();

    const { crudSuccess : shiftCrudSuccess  } = useSelector((state) => state.shift);
    const { loading, listCashiers, crudError } = useSelector((state) => state.cashier);

    const myRef = useRef();
    let main;
    
    const { listLocations } = useSelector((state) => state.location);

    const locationsList = listLocations?.results;
    const _cashiers = listCashiers?.results;


    const handleStartShiftBtn = (row, type) => {
        setShowModal(true)
        setCashType(type)
        setCashierID(row._id)
    }

    const handleEndShift = (row, type) => {
        setShowModal(true)
        setCashType(type)
        setCashierID(row._id)
    }

    const toggle = () => {
     setShowModal(false);
    //   setErrorMsg("");
    //   if (crudError) dispatch(resetErrors());
    };
    const handleAddCashGiven = (row, type) => {
        setCashierID(row._id)
        setCashType(type)
        setShowModal(true)
     }

    const handleAddCashReceived = (row, type) => {
        setCashierID(row._id)
        setCashType(type)
        setShowModal(true)
     }

   const handleStartButton = (e) => {
      e.preventDefault()
       const startShiftData = {
           cashierId: cashierID,
           cashGiven : parseInt(cashGiven)
       }
       
       const addCashReceiveData = {
           userId: cashierID,
           cash: parseInt(cashGiven),
           cashType: "receive"
       }

       const cashGivenData = {
           userId: cashierID,
           cash: parseInt(cashGiven),
           cashType: "given"
       }
       console.log({cashType})
       if (cashType === 'startShift') dispatch(handleStartShift(startShiftData))
       if (cashType === 'endShift') {
              const endPoint = "order/exportUserOrdersStats/endShift";
              const data = {
              user: cashierID,
              cashReceived: parseInt(cashGiven)
             }  
            // call export data function
           ExportShift(endPoint, "cashier", data);
           setCheckExport(!checkExport)
       }
     
       if (cashType === 'cashGiven') dispatch(handleAddDCashReceived(cashGivenData))
        
       if (cashType === 'cashReceived') dispatch(handleAddDCashReceived(addCashReceiveData))
       setCheckNull(!checkNull)
       setCashGiven("")
       setShowModal(false)
   }

    const onChangeHandler = (e) => {
        const { name, value } = e.target;
        if (name === "searchKeyword") setSearchKeyword(value);
        if (name === "locationFilter") setLocationFilter(value);
    };

    const handleRowClicked = (row) => {
        const updatedData = cashiers.map((item) => {
            if (row._id !== item._id) {
                return {
                    ...item,
                    toggleSelected: false,
                };
            }
            if (!item.toggleSelected) setCashierId(row._id);
            else setCashierId("");
            return {
                ...item,
                toggleSelected: !item.toggleSelected,
            };
        });
        setCashiers(updatedData);
    };

    const advSearchColumns = [
        {
            name: "Name",
            minWidth: "200px",
            sortable: true,

            selector: (row) => row.name,
        },
        {
            name: "Username",
            minWidth: "200px",
            sortable: true,
            selector: (row) => row.username,
        },
        {
            name: "Location",
            minWidth: "150px",
            sortable: true,
            selector: (row) => row.location?.name,
        },
        {
            name: "Cash Given",
            allowOverflow: true,
            cell: (row) => {
                return (
                    <div className="d-flex gap-1 align-items-center">
                        <span >
                            {row.cashGiven || 'N/A' }
                        </span>
                        <span
                            className="rounded-circle bg-success p-[10px]"
                            onClick={() => handleAddCashGiven(row, 'cashGiven')}
                            style={{ cursor: "pointer" }}
                        >
                            <AddPlus />
                        </span>

                    </div>
                );
            },
        },
        {
            name: "Cash Received",
            allowOverflow: true,
            cell: (row) => {
                return (
                    <div className="d-flex gap-1 align-items-center">
                        <span>
                            {row.cashReceived || 'N/A'}
                        </span>
                        <span
                            className="rounded-circle bg-success p-[10px]"
                            onClick={() => handleAddCashReceived(row, 'cashReceived')}
                            style={{ cursor: "pointer" }}
                        >
                            <AddPlus />
                        </span>

                    </div>
                );
            },
        },
        {
            name: "Shift",
            allowOverflow: true,
            cell: (row) => {
                return (
                    <div className="d-flex"> 
                    {
                            !row.cashGiven ? <Button
                                className="me-1"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleStartShiftBtn(row, 'startShift')}
                                color="primary"
                                size="sm"
                            >
                                Start
                            </Button> : <Button
                                className=""
                                onClick={() => handleEndShift(row, 'endShift')}
                                style={{ cursor: "pointer" }}
                                color="primary"
                                size="sm"
                            >
                                End
                            </Button>
                    }
                    </div>
                );
            },
        },
    ];

    const handlePagination = (page) => {
        setPage(page.selected + 1);
    };

    const conditionalRowStyles = [
        {
            when: (row) => row.toggleSelected,
            style: {
                backgroundColor: "#E1F6FF",
                "&:hover": {
                    cursor: "pointer",
                },
            },
        },
    ];
    

    // ** Custom Pagination
    const CustomPagination = () => (
        <ReactPaginate
            previousLabel={""}
            nextLabel={""}
            breakLabel="..."
            pageCount={listCashiers?.pages || 1}
            marginPagesDisplayed={2}
            pageRangeDisplayed={2}
            activeClassName="active"
            forcePage={page !== 0 ? page - 1 : 0}
            onPageChange={handlePagination}
            pageClassName={"page-item"}
            nextLinkClassName={"page-link"}
            nextClassName={"page-item next"}
            previousClassName={"page-item prev"}
            previousLinkClassName={"page-link"}
            pageLinkClassName={"page-link"}
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName={
                "pagination react-paginate separated-pagination pagination-sm justify-content-end pr-1 mt-1 mr-2"
            }
        />
    );

    useEffect(() => {
        dispatch(
            handleCashierListFetch({
                location: locationFilter,
                searchKeyword,
                page,
                limit,
            })
        );
        if (firstLoad) {
            dispatch(handleListLocationFetch({ page: 1, limit: 100 }));
            setFirstLoad(false);
        }
    }, [locationFilter, searchKeyword, page, limit]);

    useEffect(() => {
        console.log({shiftCrudSuccess, checkNull, checkExport})
        if (shiftCrudSuccess || checkNull || checkExport) {
            console.log("inside condition")
            dispatch(
                handleCashierListFetch({
                    location: locationFilter,
                    searchKeyword,
                    page,
                    limit,
                })
            );
        }
    }, [shiftCrudSuccess, checkNull, checkExport]);

    useEffect(() => {
        setCashiers(_cashiers);
    }, [_cashiers]);

    useLayoutEffect(() => {
        main = myRef.current;
        main?.scrollIntoView({ behavior: "smooth", inline: "end" });
    });

    return (
        <Fragment>
            <Card>
                <CardHeader className="border-bottom">
                    <CardTitle tag="h4">Cashiers Shift List</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row className="mt-1 mb-50 d-flex justify-content-between">
                        <Col sm="2" md="2">
                            <div className="d-flex align-items-center">
                                <Label for="sort-select">Show</Label>
                                <Input
                                    className="dataTable-select show-limit"
                                    type="select"
                                    id="sort-select"
                                    value={limit}
                                    onChange={(e) => {
                                        setLimit(e.target.value);
                                        setPage(1);
                                    }}
                                >
                                    <option value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </Input>
                                <Label for="sort-select">entries</Label>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mt-1 mb-50"></Row>
                    <Row className="mt-1 mb-50">
                        <Col lg="3" md="6" className="mb-1">
                            <Input
                                placeholder="Search"
                                type="text"
                                id="searchKeyword"
                                name="searchKeyword"
                                value={searchKeyword}
                                onChange={onChangeHandler}
                            />
                        </Col>
                        <Col lg="3" md="6" className="mb-1">
                            <Input
                                id="locationFilter"
                                name="locationFilter"
                                type="select"
                                value={locationFilter}
                                onChange={onChangeHandler}
                            >
                                <option value="">Select Location</option>
                                {locationsList?.map((location, i) => (
                                    <option key={i} value={location._id}>
                                        {location.name}
                                    </option>
                                ))}
                            </Input>
                        </Col>
                    </Row>
                </CardBody>
                {loading ? (
                    <CustomSpinner />
                ) : cashiers?.length ? (
                    <div className="react-dataTable">
                        <DataTable
                            noHeader
                            pagination
                            highlightOnHover
                            columns={advSearchColumns}
                            pointerOnHover
                            selectableRowsHighlight={true}
                            className="react-dataTable"
                            sortIcon={<ChevronDown size={10} />}
                            paginationDefaultPage={page + 1}
                            paginationComponent={CustomPagination}
                            paginationPerPage={limit}
                            onRowClicked={handleRowClicked}
                            conditionalRowStyles={conditionalRowStyles}
                            data={cashiers}
                        />
                        <div>
                            <span id="total_records">
                                <b>Total Records:</b> {listCashiers?.total || 0}
                            </span>
                        </div>
                    </div>
                ) : (
                    <Card className="mt-3">
                        <CardHeader className="d-flex align-items-center justify-content-center">
                            No Record Found!
                        </CardHeader>
                    </Card>
                )}
            </Card>
            <div ref={myRef}>
                {cashierId && (
                    <div style={{ marginBottom: "50px" }}>
                        <ShiftHistory id={cashierId} setCashierId={setCashierId}/>
                    </div>
                )}
            </div>
     <div>
        <Modal isOpen={showModal} toggle={toggle} backdrop={false} centered={true}>
                    <ModalHeader toggle={toggle}>{cashType === 'endShift' ? 'End Shift' : 'Start Shift'}</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup className="add-mb">
                <Label for="cash" className="fw-bolder mt-1">
                    {`Enter ${cashType === 'cashGiven' || cashType === 'startShift'
                     ? 'Cash Given'
                     : (cashType === 'cashReceived' || cashType === 'endShift')
                     ? 'Cash Received'
                     : ''
                     }`}
                </Label>
                <Input
                  type="number"
                  className="mt-2"
                  placeholder="20"
                  value={cashGiven}
                  onChange={(e) => setCashGiven(e.target.value)}
                />
                {<p className="text-danger m-1">{errorMsg ? errorMsg : ""}</p>}
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              disabled={loading && true}
              onClick={handleStartButton}
            >
              {cashType === 'endShift' ? 'End' : "Add"}
            </Button>
            <Button color="primary" onClick={toggle}>
              Cancel
            </Button>
            {crudError && (
              <p className="text-danger">
                {crudError.details?.length &&
                  typeof crudError.details[0] === "string"
                  ? `${crudError.msg}: ${crudError.details[0]}`
                  : crudError.details?.length &&
                    typeof crudError.details[0] === "object"
                    ? `${crudError.msg}: ${crudError.details[0].msg}`
                    : crudError?.msg}
              </p>
            )}
          </ModalFooter>
        </Modal>
      </div>
    </Fragment>
    );
};

export default CashierShift;