// ** BaseUrl
export const MAIN_SERVICE_URL = 'https://mvv-api.teamdaze.nl/api/v1'
// export const MAIN_SERVICE_URL = 'http://10.10.50.76:3000/api/v1'

export default {
  /* --------- Constants ------- */
  tokenType: "Bearer",
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken",

  /* ---------   Stat Endpoints ---------- */
  getStatEndPoint: `${MAIN_SERVICE_URL}/stat/fetch/all`,

  /* -----------Regular Orders Endpoints ------ */
  getOrderEndPoint: `${MAIN_SERVICE_URL}/order/list/all`,

  /* -----------Terminal ----------- */
  getTerminalEndPoint: `${MAIN_SERVICE_URL}/payment/terminals/all`,

  /* -----------freeze Orders Endpoints ------ */
  getFreezeOrderEndPoint: `${MAIN_SERVICE_URL}/order/list/freezeOrders`,

  /* -------- Cashier Sales Endpoints ------ */
  getCashierSalesEndPoint: `${MAIN_SERVICE_URL}/order/userOrdersStats/all`,

  /*------------Location Order Sale Stats -------*/
  getSaleStatsEndPoint: `${MAIN_SERVICE_URL}/order/locationOrdersStats/all`,

  /* -------- Export Cashier Sales Endpoints ------ */
  getUserSalesEndPoint: `${MAIN_SERVICE_URL}/order/exportUserSalesStats/all?`,

  /* ------ RFID Tool Endpoint------ */
  RfidToolEndPoint: `${MAIN_SERVICE_URL}/transaction/cashRFIDOrdersStats/all`,

  /* ------ Product Tool Endpoint ------ */
  CorrectionToolEndPoint: `${MAIN_SERVICE_URL}/order/cashProductOrdersStats/all`,

  /* --------- Product sales Stats Endpoints ---------- */
  getProductsaleStatsEndPoint: `${MAIN_SERVICE_URL}/order/productOrdersStats/all`,

  /* --------- Get Hourly Product sales Stats Endpoints ---------- */
  getHourlyProductsaleStatsEndPoint: `${MAIN_SERVICE_URL}/order/hourlyProductOrdersStats/all`,

  /* ----------- RFIDCard Endpoints --------- */
  createCard: `${MAIN_SERVICE_URL}/rfidcard`,
  getCardEndPoint: `${MAIN_SERVICE_URL}/rfidcard/list/all`,

  /* ----------- Kitchen Endpoints ------ */
  createKitchenEndpoint: `${MAIN_SERVICE_URL}/kitchen`,
  getKitchenEndpoint: `${MAIN_SERVICE_URL}/kitchen/list/all`,

  /* ----------- Product Endpoints -------- */
  productEndPoint: `${MAIN_SERVICE_URL}/product`,
  getListProductEndPoint: `${MAIN_SERVICE_URL}/product/list/all`,

  /* ----------- Event Endpoints ------------ */
  eventEndoint: `${MAIN_SERVICE_URL}/event`,
  getEventProductEndPoint: `${MAIN_SERVICE_URL}/event/list/all`,

  /* ----------- Table Endpoints ------------ */
  tableEndoint: `${MAIN_SERVICE_URL}/table`,
  tableBulkEndoint: `${MAIN_SERVICE_URL}/table/bulk`,
  getTableProductEndPoint: `${MAIN_SERVICE_URL}/table/list/all`,

  /* ----------- Accessories Endpoints ------------ */
  accessoriesEndoint: `${MAIN_SERVICE_URL}/accessory`,
  getAccessoriesEndoint: `${MAIN_SERVICE_URL}/accessory/list/all`,

  /* ---------- Category Endpoints --------- */
  categoryEndPoint: `${MAIN_SERVICE_URL}/category`,
  getCategoryListEndPoint: `${MAIN_SERVICE_URL}/category/list/all`,

  /* ----------- Transaction Endpoints ------ */
  getTransactionsEndPoint: `${MAIN_SERVICE_URL}/transaction/list/all`,
  getRefundEndPoint: `${MAIN_SERVICE_URL}/transaction/list/all?type=refund`,

  /* --------- Cashier Endpoints ---------- */
  cashierEndPoint: `${MAIN_SERVICE_URL}/user/`,
  getListCashierEndPoint: `${MAIN_SERVICE_URL}/user/list/all`,
  cashierExportEndpoint: `${MAIN_SERVICE_URL}/user/export/all`,

  /* ------ Tickets ------ */
  DeleteTicketEndPoint: `${MAIN_SERVICE_URL}/deal/ticket`,
  UpdateTicketEndPoint: `${MAIN_SERVICE_URL}/deal/ticket`,
  getTicketListEndPoint: `${MAIN_SERVICE_URL}/deal/list/tickets`,

  /* ------------- AUTH  --------------- */
  logoutEndpoint: "/jwt/logout",
  registerEndpoint: "/jwt/register",
  refreshEndpoint: "/jwt/refresh-token",
  loginEndpoint: `${MAIN_SERVICE_URL}/auth/admin/login`,

  /* ------ Location Endpoints ---------- */
  locationEndPoint: `${MAIN_SERVICE_URL}/location`,
  getListLocationEndPoint: `${MAIN_SERVICE_URL}/location/list/all`,
  locationExportEndpoint: `${MAIN_SERVICE_URL}/location/export/all`,
  getLocationExtraEndPoint: `${MAIN_SERVICE_URL}/location/extras/all`,

  /*-----------------  CREATE RFID CARD     ----------------------------*/
  createRfidCard: `${MAIN_SERVICE_URL}/rfidcard/createBulk`,
  getListofRfids: `${MAIN_SERVICE_URL}/rfidcard/list/cardCreationHistory`,
  getRFIDDetails: `${MAIN_SERVICE_URL}/rfidcard/getCardCreationHistory`,

  /** ----------- Cashier Shift ------------*/
  startShift: `${MAIN_SERVICE_URL}/user/startShift`,
  addCashShift: `${MAIN_SERVICE_URL}/user/addCash`,
  cashierHistory: `${MAIN_SERVICE_URL}/user/listShiftCashHistory`,
  
  /* --------- Admin Endpoints ---------- */
  getProfileEndPoint: `${MAIN_SERVICE_URL}/admin`,
  createAdminEndPoint: `${MAIN_SERVICE_URL}/admin`,
  updateProfileEndPoint: `${MAIN_SERVICE_URL}/admin`,
  getListAdminEndPoint: `${MAIN_SERVICE_URL}/admin/list/all`,
  updatePasswordEndPoint: `${MAIN_SERVICE_URL}/admin/password/update`,

  /* ---------- Smart Gate Endpoints ----------- */
  smartGateEndpoint: `${MAIN_SERVICE_URL}/smartgate`,

  /* ---------- Settings Endpoints ----------- */
  getSettingsEndPoint: `${MAIN_SERVICE_URL}/setting/current`,
  updateSettingsEndPoint: `${MAIN_SERVICE_URL}/setting`,

  /* ---------- Comopany Endpoints ----------- */
  getCompanyDetailsEndPoint: `${MAIN_SERVICE_URL}/company/current`,
  updateCompanyDetailsEndPoint: `${MAIN_SERVICE_URL}/company`,
};
