export const LIST_ACCESSORIES_INITIATED = "LIST_ACCESSORIES_INITIATED";
export const LIST_ACCESSORIES_SUCCESS = "LIST_ACCESSORIES_SUCCESS";
export const LIST_ACCESSORIES_FAILED = "LIST_ACCESSORIES_FAILED";

// ** create ACCESSORIES
export const CREATE_ACCESSORIES_INITIATED = "CREATE_ACCESSORIES_INITIATED";
export const CREATE_ACCESSORIES_SUCCESS = "CREATE_ACCESSORIES_SUCCESS";
export const CREATE_ACCESSORIES_FAILED = "CREATE_ACCESSORIES_FAILED";

// ** create ACCESSORIES BULK
export const CREATE_ACCESSORIES_BULK_INITIATED = "CREATE_ACCESSORIES_BULK_INITIATED";
export const CREATE_ACCESSORIES_BULK_SUCCESS = "CREATE_ACCESSORIES_BULK_SUCCESS";
export const CREATE_ACCESSORIES_BULK_FAILED = "CREATE_ACCESSORIES_BULK_FAILED";

// ** update ACCESSORIES
export const UPDATE_ACCESSORIES_INITIATED = "UPDATE_ACCESSORIES_INITIATED";
export const UPDATE_ACCESSORIES_SUCCESS = "UPDATE_ACCESSORIES_SUCCESS";
export const UPDATE_ACCESSORIES_FAILED = "UPDATE_ACCESSORIES_FAILED";

// ** update ACCESSORIES
export const DELETE_ACCESSORIES_INITIATED = "DELETE_ACCESSORIES_INITIATED";
export const DELETE_ACCESSORIES_SUCCESS = "DELETE_ACCESSORIES_SUCCESS";
export const DELETE_ACCESSORIES_FAILED = "DELETE_ACCESSORIES_FAILED";

// ** reset error
export const RESET_ERRORS = "RESET_ERRORS";
