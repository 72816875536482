import * as actionTypes from "./types";
import useJwt from "@src/auth/jwt/useJwt";
import { showSuccessMsgToast } from "../../utility/functions/common";

export const handleListSalesFetch = (data = { limit: 100 }) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.LIST_SALES_INITIATED });
      const response = await useJwt.getOrderSaleStats(data);
      dispatch({
        type: actionTypes.LIST_SALES_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      if (err.response) {
        dispatch({
          type: actionTypes.LIST_SALES_FAILED,
          payload: err.response.data,
        });
      }
    }
  };
};

export const handleListSalesDash = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.LIST_SALESDASH_INITIATED });
      const response = await useJwt.getOrderStatsDash();
      dispatch({
        type: actionTypes.LIST_SALESDASH_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      if (err.response) {
        dispatch({
          type: actionTypes.LIST_SALESDASH_FAILED,
          payload: err.response.data,
        });
      }
    }
  };
};

// cashier Sale list
export const handleCashierListSales = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.LIST_CASHIERSALES_INITIATED });
      const response = await useJwt.getCashierSaleList(data);
      dispatch({
        type: actionTypes.LIST_CASHIERSALES_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      if (err.response) {
        dispatch({
          type: actionTypes.LIST_CASHIERSALES_FAILED,
          payload: err.response.data,
        });
      }
    }
  };
};

// cashier  Sales Stats
export const handleUserSalesStats = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.EXPORT_USERSALES_INITIATED });
      const response = await useJwt.exportUserSalesStats(data);
      dispatch({
        type: actionTypes.EXPORT_USERSALES_SUCCESS,
        payload: response.data,
      });
      showSuccessMsgToast("Report successfully Download");
    } catch (err) {
      if (err.response) {
        dispatch({
          type: actionTypes.EXPORT_USERSALES_FAILED,
          payload: err.response.data,
        });
      }
    }
  };
};

// product Sale list
export const handleProductListSales = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.LIST_PRODUCT_INITIATED });
      const response = await useJwt.getProductListSales(data);
      dispatch({
        type: actionTypes.LIST_PRODUCT_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      if (err.response) {
        dispatch({
          type: actionTypes.LIST_PRODUCT_FAILED,
          payload: err.response.data,
        });
      }
    }
  };
};

// product Sale list
export const handleHourlyProductListSales = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.LIST_HOURLYPRO_INITIATED });
      const response = await useJwt.getHourlyProductListSales(data);
      dispatch({
        type: actionTypes.LIST_HOURLYPRO_SUCCESS,
        payload: response.data,
      });
    } catch (err) {
      if (err.response) {
        dispatch({
          type: actionTypes.LIST_HOURLYPRO_FAILED,
          payload: err.response.data,
        });
      }
    }
  };
};

//** RESET Errors
export const resetErrors = () => ({ type: actionTypes.RESET_ERRORS });
