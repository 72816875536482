/********************* FETCH  CASHIER LIST  ****************/
export const LIST_CASHIER_INITIATED = "LIST_CASHIER_INITIATED";
export const LIST_CASHIER_SUCCESS = "LIST_CASHIER_SUCCESS";
export const LIST_CASHIER_FAILED = "LIST_CASHIER_FAILED";

/***** CREATE CASHIER  */
export const CREATE_CASHIER_INITIATED = "CREATE_CASHIER_INITIATED";
export const CREATE_CASHIER_SUCCESS = "CREATE_CASHIER_SUCCESS";
export const CREATE_CASHIER_FAILED = "CREATE_CASHIER_FAILED";

// ** UPdate Cashier
export const UPDATE_CASHIER_INITIATED = "UPDATE_CASHIER_INITIATED";
export const UPDATE_CASHIER_SUCCESS = "UPDATE_CASHIER_SUCCESS";
export const UPDATE_CASHIER_FAILED = "UPDATE_CATEGORY_FAILED";

//  ** Get single cashier
export const GET_CASHIER_INITIATED = "GET_CASHIER_INITIATED";
export const GET_CASHIER_SUCCESS = "GET_CASHIER_SUCCESS";
export const GET_CASHIER_FAILED = "GET_CASHIER_FAILED";

// ** Delete Casier **
export const DELETE_CASHIER_INITIATED = "DELETE_CASHIER_INITIATED";
export const DELETE_CASHIER_SUCCESS = "DELETE_CASHIER_SUCCESS";
export const DELETE_CASHIER_FAILED = "DELETE_CASHIER_FAILED";

// **  reset errors
export const RESET_ERRORS = "RESET_ERRORS";
