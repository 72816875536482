// ** list product list
export const LIST_COLLECTION_INITIATED = "LIST_COLLECTION_INITIATED";
export const LIST_COLLECTION_SUCCESS = "LIST_COLLECTION_SUCCESS";
export const LIST_COLLECTION_FAILED = "LIST_COLLECTION_FAILED";

// ** list RFID tool **
export const LIST_RFID_INITIATED = "LIST_RFID_INITIATED";
export const LIST_RFID_SUCCESS = "LIST_RFID_SUCCESS";
export const LIST_RFID_FAILED = "LIST_RFID_FAILED";

// ** update RFID
export const UPDATE_RFID_INITIATED = "UPDATE_RFID_INITIATED";
export const UPDATE_RFID_SUCCESS = "UPDATE_RFID_SUCCESS";
export const UPDATE_RFID_FAILED = "UPDATE_RFID_FAILED";

// ** update Event
export const UPDATE_COLLECTION_INITIATED = "UPDATE_COLLECTION_INITIATED";
export const UPDATE_COLLECTION_SUCCESS = "UPDATE_COLLECTION_SUCCESS";
export const UPDATE_COLLECTION_FAILED = "UPDATE_COLLECTION_FAILED";

// ** reset error
export const RESET_ERRORS = "RESET_ERRORS";
